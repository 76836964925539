import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PopupdisplayComponent } from 'app/menu/popupdisplay/popupdisplay.component';
import { AccountService } from 'app/shared/account.service';
import { AuthService } from 'app/shared/auth.service';
import { BackendService } from 'app/shared/backend.service';
import CONFIG from 'config';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-securities',
  templateUrl: './securities.component.html',
  styleUrls: ['./securities.component.css']
})
export class SecuritiesComponent implements OnInit {
  
  panelOpenState = false;
  panelOpenState1 = false;
  favoriteLeader: string;
  opinions: any; opinion: any;
  dialogRef: MatDialogRef<PopupdisplayComponent>;
  proxydata: any;
  proxydatalength:number;
  QuestionNoresponse:string[]=[];
  QuestionIDs:string[]=[];
  Questionresponse:string[]=[];
  Questionlist:string[]=[];
  SecurityIDlist:string[]=[];
  SecurityTypelist:string[]=[];
  postedfrom:string[]=[];
  postedto:string[]=[];
  loadingData: boolean = false;
  currentSettlementAccountSubscription: Subscription;
  currentSettlementAccount = '';
  currentSAccount;
  issuerID:any;

  Opinions: string[] = ['Yes', 'No', 'In Person', 'Proxy', 'Abstain'];
  //Opinions1: string[] = ['Yes', 'No', 'In Person', 'Proxy', 'Abstain'];

  SubmitDate:any={};
  AccountID: string[]=[];

  constructor(public dialog: MatDialog, public _router: Router,
    public authService: AuthService,
    public backendService: BackendService,
    public _datepipe:DatePipe,
    public accountService:AccountService
  ) {

  }

  ngOnInit() 
  {
    this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
    console.log(this.currentSettlementAccountSubscription);
    this.currentSAccount = this.accountService.currentSettlementAccount;
    console.log(this.currentSAccount);

    if(this.authService.LoginType=="Issuer")
    {
      if(this.authService.IssuerCount>="1")
      {
        this.issuerID=this.authService.singleIssuerID;
      }
      else
      {
        this.issuerID=this.currentSAccount;
      }
    }
    else if(this.authService.LoginType=="ADVISOR")
    {
      this.issuerID=this.authService.CustomerID;
      console.log(this.issuerID)
    }
    else
    {
      if(this.authService.IssuerCount>="1")
      {
        this.issuerID=this.authService.singleIssuerID;
      }
      else
      {
        this.issuerID=this.currentSAccount;
      }
    }
   


    this.pageLoad();
  }
  currentSettlementAccountChange(value) {
    this.currentSAccount = value;
    
  }
  pageLoad() {
    console.log(this.authService.CustomerID);
    this.backendService.getData(`${CONFIG.proxyApiUrlBase}/${this.issuerID}`)
   //this.backendService.getData(`${CONFIG.proxyApiUrlBase}`)
      .subscribe(response => {
        console.log(response);
        this.proxydata = response['myCustomer1'];
        console.log(this.proxydata)
        this.issuerID = this.authService.CustomerID
        console.log(this.issuerID)
        console.log(this.proxydata.length);
        if(this.proxydata.length==0)
        {
          this.loadingData=true;
        }
        this.proxydatalength=this.proxydata.length;
        console.log(this.proxydata);
        this.loadingData=true;
      });
  }

  SubmitVote(votes) 
   {
    console.log("Submit the response");
    this.SubmitDate.Questionlist=this.Questionlist;
    this.SubmitDate.QuestionIDs=this.QuestionIDs;
    this.SubmitDate.Questionresponse=this.Questionresponse;
    this.SubmitDate.SecurityIDlist=this.SecurityIDlist;
    this.SubmitDate.SecurityTypelist=this.SecurityTypelist;
    this.SubmitDate.postedfrom=this.postedfrom;
    this.SubmitDate.postedto=this.postedto;
    this.SubmitDate.QuestionNoresponse=this.QuestionNoresponse;
    //this.SubmitDate.AccountID=this.AccountID;
    console.log(this.SubmitDate);
    this.backendService.postData(this.SubmitDate,`${CONFIG.proxyApiUrlBase}/${this.issuerID}`)
    //this.backendService.postData(this.SubmitDate,`${CONFIG.proxyApiUrlBase}`)
    .subscribe(response => {
      console.log(response);
      if(response['result'])
        this.pageLoad();
      
       this.proxydata = Response['myCustomer'];

    });
  }
 





onItemChange(response,item) {
  console.log(response,item.questionID,item.questionDescription,item.accountID);
    console.log(item.noResponse);
    //this.getSelecteditem();

      if(this.QuestionIDs!=null)
      {
     for(let i=0;i<this.QuestionIDs.length;i++)
      { 
       if(this.QuestionIDs[i]==item.questionID)
      {
        
        this.AccountID.splice(i,1);
        
       }
      }
}
      this.AccountID.push(item.accountID)
    
  }
}
