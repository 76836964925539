import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashHomeComponent } from './dashboard/dash-home.component';
import { ProfileComponent } from './profile/profile.component';
import { DocumentsComponent } from './menu/documents/documents.component';
import { PositionsComponent } from './menu/positions/positions.component';
import { PaymentComponent } from './menu/payment/payment.component';
import { TransactionsComponent } from './menu/transactions/transactions.component';
import { ProxyComponent } from './menu/proxy/proxy.component';
import { ReportsComponent } from './menu/reports/reports.component';
import { StatementsComponent } from './menu/statements/statements.component';
import { HoldingsComponent } from './menu/holdings/holdings.component';
import { AuthGuard } from './guards/auth.guard';
import { Utilities } from './shared/utilities.service';
import { TempcodeComponent } from './login/tempcode.component';
import { ResetFormService } from './login/reset-form.service';
import { ResetQuestionsComponent } from './reset-questions/reset-questions.component';
import { ReportViewerComponent } from './menu/report-viewer/report-viewer.component';
import { DocummentscontainerComponent } from './menu/documents/docummentscontainer/docummentscontainer.component';
import { UploaddocumentsComponent } from './menu/uploaddocuments/uploaddocuments.component';
import { AccountIdsComponent } from './account-ids/account-ids.component';
import { SecuritiesComponent } from './securities/securities.component';


const routes: Routes = [
    // {
    //     path: 'login',
    //     redirectTo: '',
    //     pathMatch: 'full'
    // },
    {
        path: '',
        component: LoginComponent
    },
    // {
    //             path: 'documents',
    //             component: DocumentsComponent
    //         },
   
    {
        path: 'login',
        component: TempcodeComponent
    },  
    {
        path: 'dashboard',
        component: DashboardComponent,
        //canActivate: [AuthGuard],
        children: [
            {

                path: '',
                component: DashHomeComponent
            },
           
            {
                path: 'profile',
                component: ProfileComponent
            },            
            {
                path: 'positions',
                component: PositionsComponent
            },
            {
                path: 'transactions',
                component: TransactionsComponent
            },  
            {
                path: 'payment',
                component: PaymentComponent
            },
            {
                path: 'securities',
                component: SecuritiesComponent
            },                    
            {
                path: 'proxy',
                component: ProxyComponent
            },   
            // {
            //     path: 'account',
            //     component: AccountIdsComponent
            // },         
            {
                path: 'documents',
                component: DocumentsComponent
            },
            {
                path: 'uploaddocuments',
                component: UploaddocumentsComponent
            },
            {
                path: 'reports',
                redirectTo: 'reports/print'
            },
            {
                path: 'reports/:id',
                component: ReportsComponent,
            },
            {
               path: 'statements',
               component: StatementsComponent
            },
            {
                  path: 'holdings',
                  component: HoldingsComponent
              },
            {
                    path:'reportview',
                    component:ReportViewerComponent
            },
           {
               path:'document',
               component:DocummentscontainerComponent,
               children:[
                   {
                       path:'',
                       component:DocumentsComponent
                   },
                   {
                       path:'documents',
                       component:DocumentsComponent
                   },
                   {
                       path:'uploads',
                       component:UploaddocumentsComponent

                   }


               ]
           }
        ]
    },
    {
        path: 'set-security-questions',
        component: ResetQuestionsComponent
      },

    //   {
    //     canActivate: [AuthGuard],
    //     path: 'profile',
    //     component: ProfileComponent
    //  },

      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
    
];
// @NgModule({
//   imports: [ RouterModule.forRoot(routes) ],
//   exports: [ RouterModule ]
// })
// export class AppRoutingModule {}
export const routing = RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules });

