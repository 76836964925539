import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio'; // Import the MatRadioModule
import { HashLocationStrategy, LocationStrategy} from '@angular/common';
import { PathLocationStrategy } from '@angular/common';





//routing
import { routing } from './app.routes';

//components
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LoadingComponent } from './loading-spinner/loading.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashHomeComponent } from './dashboard/dash-home.component';
import { NotificationComponent } from './notification/notification.component';
import { PaginationComponent } from './shared/pagination.component';
import { PasswordResendComponent } from './login/password-resend.component';
import { ProfileComponent } from './profile/profile.component';
import { TempcodeComponent } from './login/tempcode.component';

import { GoogleChart } from './shared/angular2-google-chart.directive';
//services used by whole application
import { AccountService } from './shared/account.service';
import { AuthService } from './shared/auth.service';
import { BackendService } from './shared/backend.service';
import { FilterService } from './shared/filter.service';
import { LoadingService } from './loading-spinner/loading.service';
import { NotificationService } from './notification/notification.service';
import { OrderByService } from './shared/orderby.service';
import { PaginationService } from './shared/pagination.service';
import { SearchService } from './shared/search.service';
import { Utilities } from './shared/utilities.service';

//guards
import { AuthGuard } from './guards/auth.guard';

import { DocumentsComponent } from './menu/documents/documents.component';
import { MaterialModule } from './shared/material.module';

// import {MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
// import { MatSliderModule } from '@angular/material/slider';
// import { MatAutocompleteModule } from '@angular/material/Autocomplete';
// import { MatButtonModule } from '@angular/material/Button';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
// import { MatCardModule } from '@angular/material/Card';
// import { MatCheckboxModule } from '@angular/material/Checkbox';
// import { MatChipsModule } from '@angular/material/Chips';
// import { MatDatepickerModule } from '@angular/material/Datepicker';
// import { MatExpansionModule } from '@angular/material/Expansion';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatGridListModule } from '@angular/material/grid-list';
// import { MatIconModule } from '@angular/material/Icon';
// import { MatInputModule } from '@angular/material/Input';
// import { MatListModule } from '@angular/material/List';
// import { MatMenuModule } from '@angular/material/Menu';
// import {MatPaginatorModule} from '@angular/material/paginator'
// import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatRadioModule } from '@angular/material/Radio';
// import { MatSelectModule } from '@angular/material/Select';
// import { MatSidenavModule } from '@angular/material/Sidenav';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { MatSortModule } from '@angular/material/Sort';
// import { MatTableModule } from '@angular/material/Table';
// import { MatTabsModule } from '@angular/material/Tabs';
// import { MatToolbarModule } from '@angular/material/Toolbar';
// import { MatTooltipModule } from '@angular/material/Tooltip';
// import { MatStepperModule } from '@angular/material/Stepper';
// import { MatDialogRef} from '@angular/material/Dialog'
// // import { MatNativeDateModule } from '@angular/material/';
// // import { MatRippleModule } from '@angular/material/';
// import { MAT_DIALOG_DATA } from '@angular/material/DIALOG';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PopupdisplayComponent } from './menu/popupdisplay/popupdisplay.component';
import { PositionsComponent } from './menu/positions/positions.component';
import { PaymentComponent } from './menu/payment/payment.component';
import { TransactionsComponent } from './menu/transactions/transactions.component';
import { ProxyComponent } from './menu/proxy/proxy.component';
import { ReportsComponent } from './menu/reports/reports.component';
import { StatementsComponent } from './menu/statements/statements.component';
import { HoldingsComponent } from './menu/holdings/holdings.component';

import { ContactUsDialogComponent } from './menu/contact-us/contact-us.component';
import { ResetFormService } from './login/reset-form.service';
import { ResetQuestionsComponent } from './reset-questions/reset-questions.component';
import { ReportViewerComponent } from './menu/report-viewer/report-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DocummentscontainerComponent } from './menu/documents/docummentscontainer/docummentscontainer.component';
import { UploaddocumentsComponent } from './menu/uploaddocuments/uploaddocuments.component';
import { VoteComponent } from './vote/vote.component';
import { AccountIdsComponent } from './account-ids/account-ids.component';
import { SecuritiesComponent } from './securities/securities.component';





@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    LoginComponent,
    DashboardComponent,
    DashHomeComponent,
    NotificationComponent,
    PaginationComponent,
    PasswordResendComponent,
    ProfileComponent,
    DocumentsComponent,
    GoogleChart,    
    PopupdisplayComponent,
    PositionsComponent,
    PaymentComponent,
    TransactionsComponent,
    ProxyComponent,
    ReportsComponent,
    StatementsComponent,
    HoldingsComponent,
    ContactUsDialogComponent,
    TempcodeComponent,
    ResetQuestionsComponent,
    ReportViewerComponent,
    DocummentscontainerComponent,
    UploaddocumentsComponent,
    VoteComponent,
    AccountIdsComponent,
    SecuritiesComponent,
    

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
   
    routing,
    //HttpModule,
    FormsModule,
    ReactiveFormsModule,
    //SharedModule,
    // NgxDatatableModule,
    MaterialModule,
    PdfViewerModule
  //   MatAutocompleteModule, // Material modules starts here
  //   MatButtonModule,
  //   MatButtonToggleModule,
  //   MatCardModule,
  //   MatCheckboxModule,
  //   MatChipsModule,
  //   MatTableModule,
  //   MatDatepickerModule,
  //   MatDialogModule,
  //   MatExpansionModule,
  //   MatFormFieldModule,
  //   MatGridListModule,
  //   MatIconModule,
  //   MatInputModule,
  //   MatListModule,
  //   MatMenuModule,
  //   MatPaginatorModule,
  //   MatProgressBarModule,
  //   MatProgressSpinnerModule,
  //   MatRadioModule,
  // //  MatRippleModule,
  //   MatSelectModule,
  //   MatSidenavModule,
  //   MatSlideToggleModule,
  //   MatSliderModule,
  //   MatSnackBarModule,
  //   MatSortModule,
  //   MatStepperModule,
  //   MatTabsModule,
  //   MatToolbarModule,
  //   MatTooltipModule,
  // //  MatNativeDateModule,

  ],
  entryComponents: [AppComponent, PopupdisplayComponent],
  providers: [HttpClient,
    AccountService,
    AuthGuard,
    AuthService,
    BackendService,
    CurrencyPipe,
    FilterService,
    LoadingService,
    NotificationService,
    OrderByService,
    PaginationService,
    SearchService,
    Utilities,
    DatePipe,
    ResetFormService,
   // {provide:LocationStrategy, useClass:HashLocationStrategy} 
    // { provide: MAT_DIALOG_DATA, useValue: {} },
    // { provide: MatDialogRef, useValue: {} }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }