import { Injectable,EventEmitter,Output  } from '@angular/core';
import { Subject }    from 'rxjs';

@Injectable()
export class AccountService {
    currentSettlementAccount = '';
    @Output() aClickedEvent = new EventEmitter<string>();
    @Output() dateClickedEvent = new EventEmitter<string>();
    currentSelectedDate='';
    // Observable string sources
    private currentSettlementAccountChangeConfirmedSource = new Subject<string>();
    private currentSelectedDateChangeConfirmedSource = new Subject<string>();
  
    // Observable string streams
    currentSettlementAccountChange$ = this.currentSettlementAccountChangeConfirmedSource.asObservable();
    //
    currentSelectedDateChange$ = this.currentSelectedDateChangeConfirmedSource.asObservable();
    setSAccount(currentSettlementAccount: string) {

      this.currentSettlementAccount='Refersh';
      console.log(currentSettlementAccount);
      this.currentSettlementAccount = currentSettlementAccount;
      this.currentSettlementAccountChangeConfirmedSource.next(currentSettlementAccount);
    }
    AClicked(msg: string) {
      this.aClickedEvent.emit(msg);
    }
    dateClicked(msg: string) {
      console.log(msg+"********");
      this.dateClickedEvent.emit(msg);
    }

    setDate(currentSelectedDate: string) {
      this.currentSelectedDate = currentSelectedDate;
      this.currentSelectedDateChangeConfirmedSource.next(currentSelectedDate);
    }
  
}