import { Component, OnInit, Inject, OnDestroy, Renderer2 } from '@angular/core';
import 'rxjs/add/operator/filter';
import { MatDialog , MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import CONFIG from 'config';
import { NgForm, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BackendService } from '../../shared/backend.service';
import { NotificationService } from '../../notification/notification.service';
import { AuthService } from '../../shared/auth.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsDialogComponent implements OnInit {
  newEntry: FormGroup;
  contactFormOpen: boolean = false;
  submitted: boolean = false;
  success: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ContactUsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _formBuilder: FormBuilder,
    public backendService: BackendService,
    public authService: AuthService,
    public notificationService: NotificationService,
  ) {
    this.setForm();
  }

  ngOnInit() {

  }

  onSubmit(contact) {
    console.log(contact);
    this.submitted = true;
    if (this.newEntry.valid) {
      this.success = true;
      //this.stripSelectPlaceholders(contact); getData(`${this.apiUrl}/${this.authService.CustomerID}`)   
      this.backendService.postData(contact, `${CONFIG.contactUsApiUrlBase}/${this.authService.CustomerID}/${this.authService.LoginType}`)
        //.map(response => JSON.parse(response))
        .subscribe(response => {
          //TODO: we return early for speed, but if server returns an error, notify dev?
          if (!response) {
            console.log('error processing email:', response);
          }
        }, error => {
          this.contactToggle();
          this.notificationService.showNotification({
            type: 'warning',
            message: 'There was an error processing your form- please contact jdreiling@gplains.com,kcarlson@gplains.com directly',
            timer: 0,
            closeable: true
          });
        });
    } else {
      this.notificationService.showNotification({
        type: 'error',
        message: 'Please provide required values',
        timer: 4000
      });
    }
  }
  contactToggle() {
    this.contactFormOpen = !this.contactFormOpen;
    if (this.submitted) {
      this.notificationService.hideNotification();
      this.submitted = false;
      this.setForm();
    }
    if (this.success) { this.success = false; }
  }
  onReset() { 
    this.contactToggle();
    this.setForm();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  setForm() {
    this.newEntry = this._formBuilder.group({      
      'Subject': [''],     
      'Body': ['']
    });
  }
}
