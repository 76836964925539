import CONFIG from '../../config';
import { Injectable, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../shared/auth.service';
import { BackendService } from '../shared/backend.service';
import { NotificationService } from '../notification/notification.service';
import { Utilities } from '../shared/utilities.service';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthGuard extends Utilities implements CanActivate {
    constructor(
        public _formBuilder:FormBuilder,
        public _router:Router,
        public location: Location,
        public notificationService: NotificationService,
        public authService:AuthService,
        public backendService: BackendService,
        public dialog:MatDialog
    ) {
        super(_formBuilder,_router,location, notificationService ,authService,backendService,dialog);
    }

   canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
 //  return Observable.of(true).delay(20); 
    console.log(localStorage.getItem('token'),sessionStorage.getItem('token'));
    
 //   fake login for now- no backend yet
        
        let jwt = localStorage.getItem('token') || sessionStorage.getItem('token');
        if(jwt) {
            
            return this.checkLoginForAuthGuard();
         } 
    else {
        this.authService.loggedIn = false;
         this._router.navigate(['/login']);
         return of(false);
         }
    }

    //  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> 
    //  {
    // //      let jwt = localStorage.getItem('token') || sessionStorage.getItem('token');
    // //      if(jwt) {       //  return this.checkLoginForAuthGuard();
    // //      } else {
    // //          this.authService.loggedIn = false;
    // //          this._router.navigate(['/login']);
    // //      return Observable.of(false);
    // //      }
    // //  }
    //  let jwt = localStorage.getItem('token') || sessionStorage.getItem('token');
    //     if(jwt) {
            
    //         return this.checkLoginForAuthGuard();
    //      }}
    
}