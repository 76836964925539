import { environment } from './environments/environment';
//const apiUrlBase = environment.production ? 'http://192.168.1.104:9002/': 'http://192.168.1.104:9002/'; //indian server
//const apiUrlBase = environment.production ? 'http://192.168.100.10:8224/': 'http://192.168.100.10:8224/'; //indian server
//const apiUrlBase = environment.production ?  'http://localhost:51695/'  : 'http://localhost:51695/' ;
//const apiUrlBase = environment.production ?  'https://localhost:44393'  : 'https://localhost:44393' ;//update version
//const apiUrlBase = environment.production ?  'http://pdx3.mimics.us:9002/'  : 'http://pdx3.mimics.us:9002/' ;
//const apiUrlBase = environment.production ?  'https://localhost:7178'  : 'https://localhost:7178' ;
//const apiUrlBase = environment.production ? 'https://www.wildsoft.in/GPVBACKEND/'  : 'https://www.wildsoft.in/GPVBACKEND/';  //Indian server for testing(ssl configured)/'  
//const apiUrlBase = environment.production ? 'http://192.168.1.87:8001': 'http://192.168.1.87:8001';//Client side  
//const apiUrlBase = environment.production ? 'https://www.wildsoft.in/GPVLatestBackend'  : 'https://www.wildsoft.in/GPVLatestBackend';
//const apiUrlBase = environment.production ? 'https://www.wildsoft.in/GreatPlanes_Backend'  : 'https://www.wildsoft.in/GreatPlanes_Backend';
//const apiUrlBase = environment.production ? 'http://mimics.gplains.com:8001': 'http://mimics.gplains.com:8001';//Client side//https://mimics.gplains.com:9000/
//const apiUrlBase = environment.production ? 'https://mimics.gplains.com:9000': 'https://mimics.gplains.com:9000';//client test

const apiUrlBase = environment.production ? 'https://mimicsbe.gplains.com:9443': 'https://mimicsbe.gplains.com:9443';//client_Live
const CONFIG = {
    environment: 'full',
    loginApiUrlBase: `${apiUrlBase}/api/login`,
    tempcodeApiUrlBase: `${apiUrlBase}/api/tempcode`,
    profileApiUrlBase: `${apiUrlBase}/api/ModifyPassword`,
    sendemailApiUrlBase: `${apiUrlBase}/api/ForgotPassword`,
    usersApiUrlBase: `${apiUrlBase}/api/user`,
    getSecurityDropdowns: `${apiUrlBase}/api/positions/SecurityDropdowns`,
    getSecurityDropdowns1: `${apiUrlBase}/api/positions/SecurityDropdowns`,   
    getSecurityDropdowns5: `${apiUrlBase}/api/payment/SecurityDropdowns`,
    getSecurityDropdowns3: `${apiUrlBase}/api/payment/SecurityDropdowns`,    
    getAccountsDropdowns: `${apiUrlBase}/api/PFCustomerViewGrid/dropdowns`, 
    documentsApiUrlBase: `${apiUrlBase}/api/Documents`,
    contactUsApiUrlBase: `${apiUrlBase}/api/contactus`,
    positionsApiUrlBase: `${apiUrlBase}/api/positions`,
    //paymentApiUrlBase: `${apiUrlBase}/api/positions`,
    transactionsApiUrlBase: `${apiUrlBase}/api/Transaction`,
    proxyApiUrlBase: `${apiUrlBase}/api/proxy`,
    reportApiUrlBase: `${apiUrlBase}/api/report`,
    holdingApiUrlBase: `${apiUrlBase}/api/statements`,
    AdvisorIDSelectionchnage: `${apiUrlBase}/api/AdvisorIDSelection`,
    StatementSecurityIDApiUrlBase: `${apiUrlBase}/api/StatementSecurityID`,
    imgApiUrlBase: `${apiUrlBase}/api/img`,
    uploadApiUrlBase: `${apiUrlBase}/api/upload`,
    documentsRefreshReportsApiUrlBase:`${apiUrlBase}/api/reportscheck`,
    getSecurityDropdowns2: `${apiUrlBase}/api/proxy/securityid`,
    paymentApiUrlBase: `${apiUrlBase}/api/payment`,
};

export default CONFIG;
