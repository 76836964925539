// import { Component, OnInit } from '@angular/core';
 import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PopupdisplayComponent } from '../popupdisplay/popupdisplay.component';
 import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
 import { Router } from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
import { BackendService } from 'app/shared/backend.service';
import CONFIG from 'config';
 import { DatePipe } from '@angular/common';
 import { FormControl } from '@angular/forms';
 import { NotificationService } from '../../notification/notification.service';
 import { Subscription } from 'rxjs';
 import { AccountService } from 'app/shared/account.service';

// interface Report {
//   value: string;
//   viewValue: string;
// }

// interface ReportGroup {
//   disabled?: boolean;
//   name: string;
//   report: Report[];
// }
// @Component({
//   selector: 'app-holdings',
//   templateUrl: './holdings.component.html',
//   styleUrls: ['./holdings.component.scss']
// })
// export class HoldingsComponent implements OnInit {
//   loadingData: boolean = false;
//   loadingData1: boolean = false;
//   reportGenerating: boolean = false;
//   customerName;
//   reportControl = new FormControl();
//   HolderId = new FormControl();
//   HolderShortName = new FormControl();
//   RestrictCode = new FormControl();
//   optionalFilters: FormGroup;

//   maxDate=new Date(new Date().setDate(new Date().getDate()));
//   minDate = new Date(new Date().setDate(new Date().getDate()- 365));

//   date = new FormControl(new Date());
//   begindate = new FormControl(new Date(new Date(new Date().setDate(new Date().getDate() -30))));

//   maxDate1=new Date(new Date().setDate(new Date().getDate()-30));
//   //minDate1 = new Date(new Date().setDate(new Date().getDate()));
//   //begindate = new FormControl(new Date(new Date(new Date().setDate(new Date().getDate() - 180))));
//   enddate = new FormControl(new Date());

//   securityID = new FormControl();
//   holderID = new FormControl();
//   shortname = new FormControl();

//   AdvidoID = new FormControl();

//   reportGroups: ReportGroup[] = [
//     {
//       name: 'Equity Holdings',
//       report: [
//         { value: 'Holdings', viewValue: 'Holdings' },
//       ]
//     },
//   ];
//   selectedActivityOptions: string;
//   activityOptions: string[] = ['Active Shares', 'Inactive Shares', 'Both'];
//   Filenameuser: any;

//   HolderIds: any;
//   HolderShortNames: any;
//   HolderIdsHolderShortNames:any;
//   SecIDs: any;
//   RestrictCodes: any;
//   AdvisorIdList: any;
//   filtercsutomerid: any;
//   filtershortname: any;
//   test: any;
//   CustomerType:any;
//   groupList: any = [];
//   OrginalHolderNames: any;
//   Summary: boolean =true;
//   Holdings: boolean=true;
//   CostBasis: boolean=true;
//   Activity: boolean=true;
//   PendingCerts: boolean = true;

//   currentSettlementAccountSubscription: Subscription;
//   currentSettlementAccount = '';
//   currentSAccount;
//   issuerID:any;
//   issuernames:any;

//   TextSearch: FormGroup;
//   allFiltered:any;
//   HolderShortNamesHolder:any;

//   constructor(public dialog: MatDialog, public _router: Router,
//     public authService: AuthService,
//     public backendService: BackendService,
//     public _datepipe: DatePipe,
//     public _formBuilder: FormBuilder,
//     public notificationService: NotificationService,
//     public accountService:AccountService,
//   ) {

//   }

//   ngOnInit() 
//   {


//     this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
//     console.log(this.currentSettlementAccountSubscription);
//     this.currentSAccount = this.accountService.currentSettlementAccount;
//     console.log(this.currentSAccount);




//     if(this.authService.LoginType=="Issuer")
//     {
//       if(this.authService.IssuerCount=="1")
//       {
//         this.issuerID=this.authService.singleIssuerID;
//       }
//       else
//       {
//         this.issuerID=this.currentSAccount;
//       }
//     }
//     else if(this.authService.LoginType=="ADVISOR")
//     {
//       this.issuerID=this.authService.CustomerID;
    
//     }
//     else
//     {

//       if(this.authService.IssuerCount=="1")
//       {
//         this.issuerID=this.authService.singleIssuerID;
//       }
//       else
//       {
//         this.issuerID=this.currentSAccount;
//       }
      
//     }




//     this.setForm();
//     this.getOptionalFilters1();
//     this.customerName = this.authService.CustomerName;


//     this.TextSearch = this._formBuilder.group({
//       search: ['']
//     })
//   }
//  currentSettlementAccountChange(value) {
//     this.currentSAccount = value;
    
//   }
//   getOptionalFilters() {
//     this.loadingData = true;
//     this.backendService.getData(`${CONFIG.reportApiUrlBase}/${this.issuerID}`)
//       .subscribe(response => {
//         console.log(response);
//         this.loadingData = true;
//         this.HolderIds = response["holderid"];
//         this.SecIDs = response["secid"];

//         //this.HolderShortNames = response["shortname"];      
//         this.RestrictCodes = response["restrict"];
//         this.AdvisorIdList = response['advisorList'];

//         this.HolderShortNames = response["shortname"];
//         this.HolderIdsHolderShortNames = response['holderidshortname'];
//         this.CustomerType = response["customerType"];
//         console.log(this.HolderIdsHolderShortNames, this.CustomerType);


//       });
//   }
//   chbegindate()
//   {

// //Pradeep Commenting
//     // console.log(this.begindate.value);
//     // this.minDate = new Date(new Date(this.begindate.value.getFullYear(),this.begindate.value.getMonth(),this.begindate.value.getDate()));
//     // this.maxDate=new Date(new Date(this.begindate.value.getFullYear(),this.begindate.value.getMonth(),this.begindate.value.getDate()+365));
//     //  this.enddate=new FormControl(this.maxDate);
//     // console.log(this.maxDate,this.minDate);
//     //Pradeep Commenting untill above

//     // console.log(this.enddate.value);
//     // this.maxDate=new Date(new Date(this.enddate.value.getFullYear(),this.enddate.value.getMonth(),this.enddate.value.getDate()));
//     // this.minDate = new Date(new Date(this.enddate.value.getFullYear(),this.enddate.value.getMonth(),this.enddate.value.getDate()-365));
//     // console.log(this.maxDate1);

    

//   }
//   chbenddate()
//   {

//     // console.log(this.begindate.value);
//     // this.maxDate=new Date(new Date(this.begindate.value.getFullYear(),this.begindate.value.getMonth(),this.begindate.value.getDate()));
//     // this.minDate = new Date(new Date(this.begindate.value.getFullYear(),this.begindate.value.getMonth(),this.begindate.value.getDate()-365));
//     // console.log(this.maxDate,this.minDate);


//     // console.log(this.enddate.value);
//     // this.maxDate=new Date(new Date(this.enddate.value.getFullYear(),this.enddate.value.getMonth(),this.enddate.value.getDate()));
//     // this.minDate = new Date(new Date(this.enddate.value.getFullYear(),this.enddate.value.getMonth(),this.enddate.value.getDate()-365));
//     // console.log(this.maxDate,this.minDate);
//   }
//   getOptionalFilters1() {
//     this.loadingData = true;
//     this.backendService.getData(`${CONFIG.StatementSecurityIDApiUrlBase}/${this.issuerID}`)
//       .subscribe(response => {
//         console.log(response);
//         this.loadingData = true;
//         this.SecIDs = response["secid"];
//         this.HolderIds = response["holderid"];
//         this.HolderShortNames = response["shortname"];
//         this.HolderIdsHolderShortNames = response['holderidshortname'];
//         this.CustomerType = response["customerType"];
//         this.issuernames=response["issuerName"];
//         console.log(this.HolderIdsHolderShortNames, this.CustomerType);


//         console.log(this.HolderIdsHolderShortNames);

//         for (var i = 0; i < this.HolderIdsHolderShortNames.length; i++) {
//           var test = this.HolderIdsHolderShortNames[i].customerID + ": " + this.HolderIdsHolderShortNames[i].shortName + ": " + this.HolderIdsHolderShortNames[i].customerName;
//         // var test = this.HolderIdsHolderShortNames[i].shortName + ": " + this.HolderIdsHolderShortNames[i].customerName;
//           //var test= this.HolderIdsHolderShortNames[i].shortName;
//           this.groupList.push(test);
//         }

//         this.HolderShortNames = this.groupList;
//         this.OrginalHolderNames=this.HolderShortNames;

//       });
//   }
//   FilterIssuerByHolder(value)
//   {
//     console.log(value);
//     this.HolderShortNames=[];
//     this.HolderIdsHolderShortNames=[];
//     this.groupList=[];
//     this.backendService.getData(`${CONFIG.StatementSecurityIDApiUrlBase}/${value}/${this.issuerID}`)
//       .subscribe(response => {
//         console.log(response);
//         this.HolderIdsHolderShortNames = response['holderidshortnamefilter'];

//         console.log(this.HolderIdsHolderShortNames);

//         for (var i = 0; i < this.HolderIdsHolderShortNames.length; i++) {
//           var test = this.HolderIdsHolderShortNames[i].customerID + ": " + this.HolderIdsHolderShortNames[i].shortName + ": " + this.HolderIdsHolderShortNames[i].customerName;
//         // var test = this.HolderIdsHolderShortNames[i].shortName + ": " + this.HolderIdsHolderShortNames[i].customerName;
//           //var test= this.HolderIdsHolderShortNames[i].shortName;
//           this.groupList.push(test);
//         }

//         this.HolderShortNames = this.groupList;
//         this.OrginalHolderNames=this.HolderShortNames;

//       });
//       console.log(this.HolderShortNames);

//   }

//   filterShortName(value) {

//     console.log(value);

//     let stringToSplit = value;
//     let x = stringToSplit.split(":");
//     var selectedValue = x[0];
//     // console.log(cselectedValueu);
//     if (value == "--- ALL ---") {
//       this.holderID = new FormControl("--- ALL ---");
//     }
//     else {
//       console.log(this.HolderIdsHolderShortNames);
//       this.filtercsutomerid = this.HolderIdsHolderShortNames.filter(x => x.customerID == selectedValue);

//       console.log(this.filtercsutomerid, this.filtercsutomerid[0].customerID)
//       this.holderID = new FormControl(this.filtercsutomerid[0].customerID);
     
//     }

//      // after search we bind selected dropdown and clear the search field for Holder dropdown
//      this.HolderShortNames = this.groupList;
//      this.TextSearch.controls['search'].setValue('')


//   }

//   onKey(value) { 
//     console.log(value);
//     //this.HolderShortNames = this.search(value);
//     this.search(value)
//     }
//     // Filter the HoldershortNames list and send back to populate the HoldershortNames**
//    search(value: string) { 
//     let filter = value.toLowerCase();
//   //  console.log(this.OrginalHolderNames1)
//     this.allFiltered=this.OrginalHolderNames.filter(option => option.toLowerCase().includes(filter));
//     console.log( this.allFiltered)

//     if (value.length > 0) {
//         this.HolderShortNames = this.allFiltered;
//         console.log(this.HolderShortNames)
//         //this.searchFilteredItems = allFiltered;
//       } else {
//         this.HolderShortNames = this.OrginalHolderNames;
//         console.log(this.HolderShortNames)
//       }
//    }



//   btnbackreport() {
//     console.log("calling setForm()");
//     this.setForm();
//   }

//   chSummary(value) {
//     console.log(value);
//     this.Summary = value.checked;
//   }
//   chHoldingsch(value) {
//     this.Holdings = value.checked;
//   }
//   chCostBasis(value) {
//     this.CostBasis = value.checked;
//   }
//   chActivity(value) {
//     this.Activity = value.checked;
//   }
//   // chPendingCerts(value) {
//   //   this.PendingCerts = value.checked;
//   // }
//   printReport(value) {

//     console.log(value);
//     console.log(this.reportControl.value, this.authService.LoginType);
//     // if(this.reportControl.value != "Statements")
//     // {this.Summary=false;
//     //  this.Holdings=false;
//     //  this.CostBasis=false;
//     //  this.Activity=false;
//     // }
//     this.loadingData = false;
//     this.loadingData1 = true;
//     value = {};

    

//     value.Summary = this.Summary;
//     value.Holdings = this.Holdings;
//     value.CostBasis = this.CostBasis;
//     value.Activity = this.Activity;

//     if (value.Summary == undefined)
//       value.Summary = false;
//     if (value.Holdings == undefined)
//       value.Holdings = false;
//     if (value.CostBasis == undefined)
//       value.CostBasis = false;
//     if (value.Activity == undefined)
//       value.Activity = false;

//     value.RptName = "TAStatement";
//     value.begindate = this._datepipe.transform(this.begindate.value, "MM/dd/yyyy");
//     value.enddate = this._datepipe.transform(this.enddate.value, "MM/dd/yyyy");
//     value.CustomerID = this.authService.CustomerID;
//     value.SecurityID = this.securityID.value;
//     value.holderID=this.holderID.value;
//     // value.PendingCerts = this.PendingCerts;

//     if(this.authService.LoginType=="ADVISOR")
//     {
//       value.holderID=this.holderID.value;
//     }
//     else
//     {
//       value.holderID=this.issuerID;
//     }

//     // if (value.SecurityID == undefined) {
//     //   this.loadingData = true;
//     //   this.loadingData1 = false;
//     //   this.notificationService.showNotification({ type: 'error', message: "Please select Security ID" });
//     // }
//   if( this.CustomerType=="ADVISOR" && value.holderID == undefined)
//    {
//     this.loadingData = true;
//     this.loadingData1 = false;
//     this.notificationService.showNotification({ type: 'error', message: "Please select Holder ID" });
//    }
//    else if(this.CustomerType=="ADVISOR" &&( value.holderID=="--- ALL ---" || this.shortname.value=="--- ALL ---"))
//    {
//     this.loadingData = true;
//     this.loadingData1 = false;
//     this.notificationService.showNotification({ type: 'error', message: "Please select Holder ID" });
//    }

//     else {
//       if(value.SecurityID=='--- ALL ---')
//       value.SecurityID=null;
//       //window.open("/dashboard/reportview");
//       console.log(value);
//       console.log(value, `${CONFIG.reportApiUrlBase}/${this.authService.CustomerID}`);
//       this.backendService.postData(value, `${CONFIG.reportApiUrlBase}/${this.issuerID}/${this.authService.LoginType}`)
//         .subscribe(response => {
//           console.log(response);
//           if (response['result']) {
//          //   this.delay(15000).then(any=>{
//             //this.setForm();
//             this.loadingData = true;
//             this.loadingData1 = true;
//             this.reportGenerating = true;

//             // this.Filenameuser = response["value"];
           
//             // sessionStorage.setItem('Filename', this.Filenameuser);
//             // this.authService.Filename = sessionStorage.getItem('Filename');
//             // sessionStorage.setItem('backendresponse', response['result']);
//             // this.authService.backendresponse = sessionStorage.getItem('backendresponse');
//             // this.openFile();
//         //  });
//           }
//           else {
//             this.notificationService.showNotification({ type: 'error', message: response['msg'] });
//           }
//           console.log(response)
//           //this.filename();

//         },
//         (error) => {
//           this.loadingData = true;
//           this.loadingData1 = false;
//           this.notificationService.showNotification({ type: 'error', message: 'Server Error - Please try again.' });
//          // this.pageload = true;
//       });
        
//     }
//   }
//   async delay(ms: number) {
//     await new Promise(resolve => setTimeout(()=>resolve(), ms)).then(()=>console.log("fired"));
// }
//   cancel() {
//     this.reportControl.setValue('');
//     this.setForm();
//   }
//   openFile() {
//     console.log("test loaded");
//    window.open("../assets/reports/" + this.Filenameuser);
//     // window.open("F:/reports/anil.PDF");
//   }

//   // openFile() {

//   //   window.open("/dashboard/reportview");
//   // }
//   // filterShortName(value) 
//   // {

//   //   console.log(value);
//   //   if(value=="--- ALL ---")
//   //   {
//   //     this.holderID = new FormControl("--- ALL ---");
//   //   }
//   //   else
//   //   {
//   //   this.filtercsutomerid = this.HolderIdsHolderShortNames.filter(x => x.shortName == value);
//   //   console.log(this.filtercsutomerid, this.filtercsutomerid[0].customerID)
//   //   this.holderID = new FormControl(this.filtercsutomerid[0].customerID);
//   //   }
   

//   // }
//   filterHolderid(value) 
//   {
//     console.log(value);
//     if(value=="--- ALL ---")
//     {
//       this.shortname = new FormControl("--- ALL ---");
//     }
//     else
//     {
//       this.filtershortname = this.HolderIdsHolderShortNames.filter(x => x.customerID == value);
//       console.log(this.filtershortname, this.filtershortname[0].shortName)
//       this.shortname = new FormControl(this.filtershortname[0].shortName);
//     }
//   }

//   setForm() {

//     this.date = new FormControl(new Date());
//     this.begindate = new FormControl(new Date(new Date(new Date().setDate(new Date().getDate() - 30))));
//     this.enddate = new FormControl(new Date());
//     this.securityID = new FormControl();
//     this.holderID = new FormControl();
//     this.shortname = new FormControl();
//     this.AdvidoID = new FormControl();
//     this.RestrictCode = new FormControl();

//     // this.optionalFilters = this._formBuilder.group({
//     //     'date': ['', Validators.required],
//     //     'HolderId': ['',],     
//     //     'HolderShortName': [''],
//     //     'RestrictCode': ['']         

//     // });
//   }
// }
//dynamic
// import { Component, OnInit } from '@angular/core';
// import { Holding } from './holding.model ';


// import { HoldingService } from './holding.service';



// @Component({
//   selector: 'app-holdings',
//   templateUrl: './holdings.component.html',
//   styleUrls: ['./holdings.component.css'],
// })
// export class HoldingsComponent implements OnInit {
//   holdings: Holding[] = [];

//   constructor(private holdingService: HoldingService) {}

//   ngOnInit(): void {
//     this.holdings = this.holdingService.getHoldings();
//   }
// }



//data
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-holdings',
  templateUrl: './holdings.component.html',
  styleUrls: ['./holdings.component.css']
})
export class HoldingsComponent implements OnInit {
  holdings: any[];

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.fetchHoldings();
  }

  fetchHoldings() {
    this.http.get<any[]>('/api/holdings').subscribe(data => {
      this.holdings = data;
    });
  }
}
