import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NgForm, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
import { BackendService } from '../shared/backend.service';
import { AuthService } from '../shared/auth.service';
//import { NotificationService } from '../notification.service';
import { Utilities } from '../shared/utilities.service';
import CONFIG from '../../config';
import { NotificationService } from '../notification/notification.service';
import { MatDialog } from '@angular/material/dialog';

export interface SecurityQuestion {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-reset-questions',
  templateUrl: './reset-questions.component.html',
  styleUrls: ['./reset-questions.component.scss']
})
export class ResetQuestionsComponent extends Utilities implements OnInit {

  result: any;
  msg: any;
  id: string;
  updatedProfile: FormGroup;
  Password: FormControl;
  passwordStrength: number = 0;
  updatingPassword: boolean = false;
  passwordConfirmed: boolean = true;
  submitted: boolean = false;
  availableResetQuestions: any;
  selected_Q1: string = '';
  selected_Q2: string = '';
  securityquestions: SecurityQuestion[] = [
        { value: 1, viewValue: 'What was your childhood nickname?' },
        { value: 2, viewValue: 'What was your dream job as a child?' },
        { value: 3, viewValue: 'Where is your favorite place to travel?' },
        { value: 4, viewValue: "What is Mother's maiden name?" },
        { value: 5, viewValue: 'What was the name of the town/city you were born?' },
        { value: 6, viewValue: 'Which high school did you attend?' }
  ];

  constructor(
    public _formBulider: FormBuilder,
    public _router: Router,
    public location: Location,
    public notificationService: NotificationService,
    public backendService: BackendService,
    public authService: AuthService,
    public dialog: MatDialog
  ) {
    super(_formBulider, _router, location, notificationService, authService, backendService, dialog);
    this.setForm();

  }

  ngOnInit() {
    this.getMyProfile();
  }

  //   updateProfile(updatedProfile) {
  //     if(updatedProfile.ModifyPassword == ''){
  //         this.notificationService.showNotification({ message: 'Please Update Password', type: 'warning' });
  //     }
  //     if(updatedProfile.PwdResetQuestion1 == updatedProfile.PwdResetQuestion2){
  //         this.notificationService.showNotification({ message: 'Please Select Two different questions', type: 'warning' });
  //     }
  //     else{
  //     //console.log(updatedProfile , this.passwordConfirmed);
  //     this.submitted = true;
  //     //console.log(this.updatedProfile.valid , this.passwordConfirmed);
  //     if (this.updatedProfile.valid && this.passwordConfirmed) {
  //         this.backendService.putData(updatedProfile, `${CONFIG.changePasswordApiUrlBase}/${this.authService.UserID}`)
  //             .subscribe(
  //             response => {
  //                 this.submitted = false;
  //                 this.notificationService.showNotification({ message: response['msg'], type: 'success' });
  //                 this.authService.logout();
  //             },
  //             error => {
  //                 this.serverErrorOrTimeout(error);
  //             });
  //     } else {
  //         //validators handle this case
  //         //console.log('error with form');
  //     }}
  // }
  updateProfiledata2(updatedProfile) {
    this.backendService.postData(updatedProfile, `${CONFIG.profileApiUrlBase}/update/edit/${this.authService.CustomerID}/${this.authService.UserID}/${this.authService.LoginType}`)
      .subscribe(response => {
          //console.log("test1234");
          if (response['result']) {
            //console.log("test123456");
            this.submitted = false;
            this.notificationService.showNotification({ message: response['msg'], type: 'success' });
            this.authService.logout();
          }
        }
        , error => {
          this.serverErrorOrTimeout(error);
        });
  }

  updateProfile(updatedProfile) {
    if (updatedProfile.ModifyPassword == '') {
      this.notificationService.showNotification({ message: 'Please Update Password', type: 'warning' });
    }
    //console.log(updatedProfile.PwdResetQuestion1, updatedProfile.PwdResetQuestion2);
    if (updatedProfile.PwdResetQuestion1 == updatedProfile.PwdResetQuestion2) {
      this.notificationService.showNotification({ message: 'Please Select Two different questions', type: 'warning' });
    }
    else {
      //console.log(updatedProfile, this.passwordConfirmed);
      this.submitted = true;
      //console.log(this.updatedProfile.valid, this.passwordConfirmed);
      if (this.updatedProfile.valid && this.passwordConfirmed) {
        this.backendService.postData(updatedProfile, `${CONFIG.profileApiUrlBase}/reset/${this.authService.CustomerID}/${this.authService.UserID}/${this.authService.LoginType}`)
          .subscribe(
            response => {
              //console.log("test1");
              //console.log("response"+response);
              this.submitted = false;
              if (!response['result']) {
                //console.log("test alert");
                this.notificationService.showNotification({ message: response['msg'], type: 'warning' });
                //this.alert.openConfirmationDialog(response['msg'],response['result']);
                this._router.navigate(['/profile']);
                this.setForm();
              }
              else {
                //console.log("test12");
                this.updateProfiledata2(updatedProfile);
                //     this.openDialog(updatedProfile);
                //     this.notificationService.showNotification({ message: response['msg'], type: 'success' });
                // this.authService.logout();
              }
            },
            error => {
              //console.log("test123");
              this.serverErrorOrTimeout(error);
            });
      } else {
        //validators handle this case
        //console.log('error with form');
        this.notificationService.showNotification({ message: "New password and Confirm password does not match.", type: 'warning' });
      }
    }
  }

  getMyProfile() {
    //console.log('gfdklgndgkldglk');
    this.backendService.getData(`${CONFIG.profileApiUrlBase}/${this.authService.UserID}/${this.authService.CustomerID}/${this.authService.LoginType}`)
      .subscribe(response => {
        //console.log("getMyProfile method");
        if (!response) {
          this.notificationService.showNotification({ timer: 5000, message: response['msg'], type: 'error', closeable: false });
        }

        //set the id - we use this to identify the profile when updating below instead of the 
        //email because the email can be changed- this cannot
        //this.id = profileData.id;

        this.updatedProfile.controls['ModifyPassword'].valueChanges
          //.debounceTime(200)
          .subscribe((value) => {
            this.passwordConfirmed = value === '';
            this.updatingPassword = value !== '';
          });

        this.updatedProfile.controls['ConfirmPassword'].valueChanges
          .subscribe((value) => {
            this.passwordConfirmed = this.updatedProfile.controls['ModifyPassword'].value === value;
          });
      },
        error => {
          this.serverErrorOrTimeout(error);
        });
  }


  cancel() {
    // this._loginService.resetQuestionsSet = true;
    this.authService.logout();
  }


  setForm() {
    this.updatedProfile = this._formBulider.group({
      'Password': ['', Validators.minLength(3)],
      'ModifyPassword': ['', Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+.)(/]).{8,12}$')],
      'ConfirmPassword': ['', Validators.required],
      'PwdResetQuestion1': ['', Validators.required],
      'PwdResetQuestion2': ['', Validators.required],
      'PwdResetAnswer1': ['', Validators.required],
      'PwdResetAnswer2': ['', Validators.required]
    });
  }

}
