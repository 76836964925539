import { Component, Input, OnInit } from '@angular/core';
import { PopupdisplayComponent } from '../popupdisplay/popupdisplay.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
import { BackendService } from 'app/shared/backend.service';
import CONFIG from 'config';
import { DatePipe } from '@angular/common';
import { AccountService } from 'app/shared/account.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-proxy',
  templateUrl: './proxy.component.html',
  styleUrls: ['./proxy.component.scss'],
  providers: [ DatePipe],
 // template:`<app-documents [date]="formatdate1"></app-documents>`
})
export class ProxyComponent implements OnInit {
   dateRequested: string="";
   isProxyActive3= false;
  //selectedOpinion = 'No';
  filteredProxyData: any[] = [];
  orderByService: any = {};
  showProxy = true;
  showDetails = false;
  selectedIndex: number = -1;
  isRadioButtonSelected: boolean = false;
  panelOpenState = false;
  panelOpenState1 = false;
  favoriteLeader: string;
  opinions: any; opinion: any;
  dialogRef: MatDialogRef<PopupdisplayComponent>;
  proxydata: any;
  proxydatalength:number;
  QuestionNoresponse:string[]=[];
  QuestionIDs:string[]=[];
  Questionresponse:string[]=[];
  Questionlist:string[]=[];
  SecurityIDlist:string[]=[];
  SecurityTypelist:string[]=[];
  postedfrom:string[]=[];
  postedto:string[]=[];
  loadingData: boolean = false;
  currentSettlementAccountSubscription: Subscription;
  currentSettlementAccount = '';
  currentSAccount;
  issuerID:any;
  TotalAccountID:string[]=[];
  Opinions: string[] = ['For', 'Against', 'Abstain'];
  //Opinions1: string[] = ['Yes', 'No', 'In Person', 'Proxy', 'Abstain'];
  //filteredProxyData: any[] = [];
  SubmitDate:any={};
  AccountID: string[]=[];
  TotalShares: any;
  Totalsharesof: string[]=[];
  TotalResponse: string[]=[];
  proxy: any;
  FilterByStatus: any;
  filetrSecurityTypeaudit: any;
  filetraudit: any;
  filtersecType: any;
  totalEntries: any;
  entryRangeSubscription: any;
  paginationService: any;
  pagenlenth: boolean;
  items: any;
  SecurityID :any;
  entryRange: any;
  exportItems: any;
  proxydata1: any;
  securityIDList: any;
  securities: any;
  currentShares: any;
  securityTypeList: any;
  originalProxyData1 = [];
  proxydataa: any;
  TotlAccountValue: any;
  proxydataa1: any;
  proxydataa2: any;
  filteredProxyData1: any[];
  proxydata44: any[];
  showvote: boolean;
  checkedItems: boolean[] = [];
  currentYear: number;
  meetingtome: any;
  meetingtime: any;
  Location: any;
  recorddate: any;
  securityIDList1: any;
  documents: any;
  maxdocid: any;
  filteredDocuments: Object[] = [];
  onlymeetingdate: any;
  formattedDate: string;
  formatdate1: any;
  recorddateolny: any;
  onlymeetingdate1: any;
  sec: any;
  testdate: any;
  clientname: any;
  accountiddrop: any;
  recorddateonlysec: any;
  meetingsecdate: any;
  companyname: string[];
  tempDocdate: number;
  
  constructor(public dialog: MatDialog, public _router: Router,
    public authService: AuthService,
    public backendService: BackendService,
    public _datepipe:DatePipe,
    public accountService:AccountService
    
  ) {

  }
  ngOnChanges(){

  }

  ngOnInit() 
  {
    this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
    console.log(this.currentSettlementAccountSubscription);
    this.currentSAccount = this.accountService.currentSettlementAccount;
    console.log(this.currentSAccount);

    if(this.authService.LoginType=="Issuer")
    {
      if(this.authService.IssuerCount>="1")
      {
        this.issuerID=this.authService.singleIssuerID;
      }
      else
      {
        this.issuerID=this.currentSAccount;
      }
    }
    else if(this.authService.LoginType=="ADVISOR")
    {
      this.issuerID=this.authService.CustomerID;
      console.log(this.issuerID)
    }
    else
    {
      if(this.authService.IssuerCount>="1")
      {
        this.issuerID=this.authService.singleIssuerID;
      }
      else
      {
        this.issuerID=this.currentSAccount;
      }
    }
    this.accountService.setDate("");

    this.getSecurityDropdowns2();
    this.pageLoad();
    this.getSecurityDropdowns1();
    this.FilterByStatus = new FormGroup({
      securityID: new FormControl(''),
      securityIDName:new FormControl(''),
      securityIDacc:new FormControl('')
    });
    //this.selectedOpinion = 'No';
    this.filteredProxyData1 = this.proxydata1;
    this.currentYear = new Date().getFullYear();
    
    
    //this.setMeetingDay();
  }
  currentSettlementAccountChange(value) {
    this.currentSAccount = value;
    
  }
  pageLoad() {
    console.log(this.authService.CustomerID);
    this.backendService.getData(`${CONFIG.proxyApiUrlBase}/securityid/${this.issuerID}`)
   // this.backendService.getData(`${CONFIG.proxyApiUrlBase}`)
      .subscribe(response => {
        console.log(response);
        // this.proxydata = response['myCustomer'];
        this.proxydata1 = response['myCustomer1'];
        //this.checkedItems = new Array(this.proxydata1.length).fill(false);

this.sec=response['securityId'];
console.log(this.sec)
        this.recorddateolny=response['recorddateonly'];
        console.log(this.recorddateolny)
        this.onlymeetingdate1=response['dateOnly1'];
        console.log(this.onlymeetingdate1)
        console.log(this.checkedItems)
        console.log(this.proxydata1)
        this.issuerID = this.authService.CustomerID
        this.SecurityID=this.authService.CustomerID
         console.log(this.issuerID)
         console.log(this.SecurityID)
        // console.log(this.proxydata.length);
        // if(this.proxydata.length==0)
        // {
        //   this.loadingData=false;
        // }
        // this.proxydatalength=this.proxydata.length;
        
        // console.log(this.proxydata);
         this.loadingData=true;
      });
  }

  toggleCheckbox(checked: boolean, index: number) {
    this.checkedItems[index] = checked;
  }

  
  setGrid(arg0: number, totalEntries: any) {
    throw new Error('Method not implemented.');
  }
  getDetails(meetingDate,securityID,recorddate){
    console.log(meetingDate)
    console.log(securityID)
    let startdate = this._datepipe.transform(meetingDate, "MM-dd-yyyy");
    let recordDate1=this._datepipe.transform(recorddate, "MM-dd-yyyy");
    console.log(startdate)
    // this.securityIDList = this.FilterByStatus.controls.securityID.value;
    // this. securityIDList1 = this.FilterByStatus.controls.securityIDName.value;
    // console.log(this.securityIDList)
    // console.log(this. securityIDList1)
    
    //console.log ("printed");
    //this.pageLoad(); 
    //{
      console.log(this.authService.CustomerID);
      // this.backendService.getData(`${CONFIG.proxyApiUrlBase}/${this.issuerID}/${this.securityIDList}/${this. securityIDList1}`)
      // this.backendService.getData(`${CONFIG.proxyApiUrlBase}/${this.issuerID}/${this.recorddateolny}/${this.onlymeetingdate1}/${this.sec}`)
      
      //this.backendService.getData(`${CONFIG.proxyApiUrlBase}/${this.issuerID}/${this.recorddateolny}/${startdate}/${securityID}`)
      this.backendService.getData(`${CONFIG.proxyApiUrlBase}/${this.issuerID}/${recordDate1}/${startdate}/${securityID}`)
     // this.backendService.getData(`${CONFIG.proxyApiUrlBase}`)
        .subscribe(response => {
          console.log(response);
          this.proxydata = response['myCustomer'];
          console.log(this.proxydata)
          //this.proxydataa = response['myshares'];
          this.proxydataa=response['formattedNumber']
          //this.proxydataa1=response['totalSharevalue'];
          this.proxydataa1=response['formattedNumber1'];
          this.proxydataa2=response['securityId1'];
          this.meetingtime=response['meetingdatetime'];

          this.meetingsecdate=response['meetingdatesec']
          this.accountiddrop=response['accountiddrop'];
          console.log(this.accountiddrop)

          this.onlymeetingdate=response['meetingdate']
          const date = new Date(this.onlymeetingdate);
          const formattedDate = date.toLocaleDateString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric'
          });
          console.log(formattedDate)
          //this.testdate=response[formattedDate];
          //console.log(this.testdate)
          this.Location=response['location'];
          this.recorddate=response['recorddate'];
          console.log(this.recorddate)

          console.log(this.meetingtime)
          this.recorddateolny=response['recorddateonly'];
          console.log(this.recorddateolny)
          this.recorddateonlysec=response['recorddateonlysec']
          this.formatdate1=response['dateOnly']
          console.log(this.formatdate1)
          this.clientname=response['client']
          console.log(this.onlymeetingdate)
          console.log(this.proxydataa2)
          this.issuerID = this.authService.CustomerID
          console.log(this.issuerID)
          console.log(this.proxydata.length);
          this.companyname=response['companyname'];
          console.log(this.companyname)
          console.log(this.proxydata);
          if(this.proxydata.length==0)
          {
            this.loadingData=true;
          }
          this.proxydatalength=this.proxydata.length;
          
          console.log(this.proxydata);
          this.loadingData=true;
          this.showDetails = true;
          this.showProxy = false;
        });
    //}

  }
  meetingDay: string = '';
//   setMeetingDay(): void {
//     const meetingTime = new Date(this.meetingtime);
// console.log(meetingTime)
//     if (!isNaN(meetingTime.getTime())) {
//       const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
//       this.meetingDay = days[meetingTime.getDay()];
//     } else {
//       this.meetingDay = "Invalid Date";
//     }
//   }
testDate(){
  this.dateRequested=this.formatdate1;
}
  SubmitDocument(){
    
//    console.log(this.formatdate1)
// //     this.formattedDate = `${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}-${date.getFullYear()}`;
//     this.backendService.getData(`${CONFIG.documentsApiUrlBase}/${this.issuerID}/${this.authService.LoginType}/${this.formatdate1}`)
//       .subscribe(response => {
//         this.documents = response["documents"];
//         this.maxdocid=response["maxdocid"];
//         this.tempDocdate = 0;
//         //this.meetingtime=response['meetingdatetime'];
//         console.log(this.documents);
//         this.filteredDocuments = this.documents;
//         //this.totalEntries = this.filteredDocuments.length;
//         this.loadingData = true;
//         this.pagenlenth = false;
       
//         // if (this.filteredDocuments.length === 0) {
//         //   this.noEntrys = true;
//         // }
//         //this.orderBy(this.defaultSortOrder, this.totalEntries);
//         // this.setGrid(1, this.totalEntries);
//         //this.paginationSubscription();
//       },
//         error => {
//           // this.serverErrorOrTimeout(error);
//         });
       this.dateRequested=this.formatdate1;
      console.log(this.dateRequested);
      this.accountService.setDate(this.dateRequested);
       this._router.navigate(['/dashboard/document']);
       this.isProxyActive3= true;
        //this._router.navigate(['/dashboard/document', { date: this.formatdate1 }]);
        //this._router.navigate(['/dashboard/document',{ documents: this.documents }]);

      //   this.backendService.getData(`${CONFIG.documentsApiUrlBase}/${this.issuerID}/${this.authService.LoginType}/${this.formatdate1}`)
      // .subscribe(response => {
      //   this.documents = response["documents"];
      //   this.maxdocid=response["maxdocid"];
      //   //this.meetingtime=response['meetingdatetime'];
      //   console.log(this.documents);
      //   this.filteredDocuments = this.documents;
      //   //this.totalEntries = this.filteredDocuments.length;
      //   this.loadingData = true;
      //   this.pagenlenth = false;
       
      //   // if (this.filteredDocuments.length === 0) {
      //   //   this.noEntrys = true;
      //   // }
      //   //this.orderBy(this.defaultSortOrder, this.totalEntries);
      //   // this.setGrid(1, this.totalEntries);
      //   //this.paginationSubscription();
      // },
      //   error => {
      //     // this.serverErrorOrTimeout(error);
      //   });
      }
  Submitback(votes){
    
    this.showProxy = true;
    this.showDetails = false;
    this.pageLoad()
    this.getSecurityDropdowns2()
    this.getSecurityDropdowns1()
    console.log("post")
    this.checkedItems = new Array(this.proxydata1.length).fill(false);
if(this.QuestionNoresponse==this.Totalsharesof){
  this.checkedItems=new Array(this.proxydata1.length).fill(false);
  }
  else{
    this.checkedItems=new Array(this.proxydata1.length).fill(true);
    console.log(this.checkedItems)
    console.log(this.QuestionNoresponse==this.Totalsharesof)
  }
    console.log(this.QuestionNoresponse==this.Totalsharesof)
  }

  SubmitVote(votes) 
  {
    //var meetingDate=
    console.log(votes)
    console.log("Submit the response");
    this.SubmitDate.Questionlist=this.Questionlist;
    this.SubmitDate.QuestionIDs=this.QuestionIDs;
    this.SubmitDate.Questionresponse=this.Questionresponse;
    this.SubmitDate.SecurityIDlist=this.SecurityIDlist;
    this.SubmitDate.SecurityTypelist=this.SecurityTypelist;
    this.SubmitDate.postedfrom=this.postedfrom;
    this.SubmitDate.postedto=this.postedto;
    this.SubmitDate.QuestionNoresponse=this.QuestionNoresponse;
    this.SubmitDate.Totalsharesof=this.Totalsharesof;
    this.SubmitDate.TotalAccountID=this.TotalAccountID;
    //this.SubmitDate.TotalResponse=this.TotalResponse
    console.log(this.SubmitDate.TotalAccountID)
    console.log(this.SubmitDate);
   // this.TotalAccountID=this.SubmitDate;
    let meetinddatesec=this._datepipe.transform(this.meetingsecdate, "MM-dd-yyyy");
    console.log(meetinddatesec)
    //let shortname=this.proxydata.shortname;
    //console.log(shortname)
   // this.backendService.postData(this.SubmitDate,`${CONFIG.proxyApiUrlBase}/${this.issuerID}/${this.securityIDList}/${this.SubmitDate.TotalAccountID}`)
    this.backendService.postData(this.SubmitDate,`${CONFIG.proxyApiUrlBase}/${this.issuerID}/${this.proxydataa2}/${this.SubmitDate}/${meetinddatesec}`)
    .subscribe(response => {
      console.log(response);
       if(response['result']){
        //this.proxydata.splice(this.SubmitDate);
        //console.log(this.proxydata.splice(this.SubmitDate))
        // Reset selected index
        //this.selectedIndex = -1;
       console.log(this.QuestionNoresponse)
       console.log(this.Totalsharesof)
       //let startdate = this._datepipe.transform(this.proxydata.meetingDate, "MM-dd-yyyy");
       // console.log(startdate)
       
       
       //this.pageLoad()
       this.filterSecurityIDAcc()
      // this.Submitback(votes)
      let meetinddatesec=this._datepipe.transform(this.meetingsecdate, "MM-dd-yyyy");
    console.log(meetinddatesec)
    let recorddate2=this._datepipe.transform(this.recorddateonlysec, "MM-dd-yyyy");
    console.log(recorddate2)
      // this.getDetails(meetinddatesec,this.proxydataa2,recorddate2);
// console.log(meetinddatesec,this.proxydataa2,recorddate2)
//         console.log(this.proxydata.meetingDate,this.proxydata.securityID)
//          this.proxydata.splice(this.SubmitDate);
//          console.log(this.proxydata.splice(this.SubmitDate))
        //  if(this.proxydata.splice(this.SubmitDate)==0){
        //   this.Submitback(votes)
        //  }
      }
      
        this.proxydata = response['myCustomer'];
        //this.proxydata.splice(this.SubmitDate);
        document.getElementById('statusText').innerText = 'STATUS:Voted';
        document.getElementById('statusButton').style.backgroundColor = 'green';

    });




    // this.dialogRef = this.dialog.open(PopupdisplayComponent, {
    //   height: '140px',
    //   width: '600px',
    //   data: {},
    //   disableClose: true,

    // });
    // this.dialogRef.componentInstance.confirmMessage = "Your votes have been submitted. Thank you.";
    // this.dialogRef.afterClosed().subscribe(result => {
    //   if (result) {

    //     this._router.navigate(['/dashboard']);

    //   }
    //   this.dialogRef = null;
    // });
    ///
    
  }
 





onItemChange(response,item) {
  console.log(response,item.questionID,item.questionDescription,item.shortname,item.totalShares);
    console.log(item.noResponse);
    //this.getSelecteditem();

      if(this.QuestionIDs!=null)
      {
     for(let i=0;i<this.QuestionIDs.length;i++)
      { 
       if(this.QuestionIDs[i]==item.shortname)
      {
        this.QuestionIDs.splice(i,1);
        console.log(this.QuestionIDs.splice(i,1))
        this.Questionresponse.splice(i,1);
        this.Questionlist.splice(i,1);
        this.SecurityIDlist.splice(i,1);
        this.SecurityTypelist.splice(i,1);
        this.postedfrom.splice(i,1);
        this.postedto.splice(i,1);
        this.QuestionNoresponse.splice(i,1);
        this.TotalAccountID.splice(i,1);
        this.Totalsharesof.splice(i,1);
        this.TotalResponse.splice(i,1)
       }
      }
      //this.isRadioButtonSelected = true;
}
      this.TotalResponse.push(item.noresponse)
      this.Totalsharesof.push(item.totalShares)
      //this.TotalAccountID.push(item.accountID)
      this.TotalAccountID.push(item.shortname)
      this.QuestionIDs.push(item.questionID);
     this.Questionresponse.push(response);
      this.Questionlist.push(item.questionDescription);
      this.SecurityIDlist.push(item.securityID);
      this.SecurityTypelist.push(item.securityIDType);
     //this.postedfrom.push(this._datepipe.transform(item.proxyPostedFrom.toString(),"MM/dd/yyyy"));
      //this.postedto.push(this._datepipe.transform(item.proxyPostedTo.toString(),"MM/dd/yyyy"));
      // this.postedfrom.push(this._datepipe.transform(item.proxyPostedFrom.toString(),"yyyy/MM/dd"));
      // this.postedto.push(this._datepipe.transform(item.proxyPostedTo.toString(),"yyyy/MM/dd"));
      this.QuestionNoresponse.push(item.noResponse);
   console.log(this.QuestionIDs,this.Questionresponse);
   this.isRadioButtonSelected = true;
  }
  getSecurityDropdowns2() {
    console.log(this.issuerID);
    this.backendService.getData(`${CONFIG.proxyApiUrlBase}/securityid/${this.issuerID}`)
      // .subscribe(response => {(`${CONFIG.getSecurityDropdown}/${this.authService.CustomerID}`)
      //     this.currency = response.map(item => item);
      .subscribe(response => {
        this.securityIDList=response['securityId'];
        console.log(this.securityIDList);
        this.securities = response['data'];
        this.FilterByInvestIDandDate(this.currentShares.value);
        for (var i = 0; i < this.securities.length; i++) {
          var secID = this.securities[i].securityIDNumber;
          var secType = this.securities[i].securityIDType;
          //console.log(test);
          this.securityIDList.push(secID);
          this.securityTypeList.push(secType);
        }
        console.log(this.securities);
      });
  }


  getSecurityDropdowns1() {
    console.log(this.issuerID);
    this.backendService.getData(`${CONFIG.positionsApiUrlBase}/SecurityDropdowns/${this.issuerID}`)
      // .subscribe(response => {(`${CONFIG.getSecurityDropdown}/${this.authService.CustomerID}`)
      //     this.currency = response.map(item => item);
      .subscribe(response => {
        this.securityIDList1=response['securityId'];
        console.log(this.securityIDList);
        this.securities = response['data'];
        this.FilterByInvestIDandDate(this.currentShares.value);
        for (var i = 0; i < this.securities.length; i++) {
          var secID = this.securities[i].securityIDNumber;
          var secType = this.securities[i].securityIDType;
          //console.log(test);
          this.securityIDList.push(secID);
          this.securityTypeList.push(secType);
        }
        console.log(this.securities);
      });
  }

  FilterByInvestIDandDate(value: any) {
    throw new Error('Method not implemented.');
  }
  clearSecurityID(){
    console.log("tets");
    console.log(this.proxydata1);
    this.FilterByStatus.controls['securityID'].setValue('');
    this.setGrid(1, this.proxydata1.length);
    this.entryRangeSubscription = this.paginationService.entryRange
    .subscribe(range => {
      this.items = this.proxydata1.slice(this.entryRange[0], this.entryRange[1]);
      this.exportItems = this.proxydata1;
      this.totalEntries = this.proxydata1.length;
      this.entryRange = range;
    });
  }
  filterSecurityID() {
    
    const selectedSecurityID = this.FilterByStatus.get('securityID').value;
    console.log(selectedSecurityID)
    this.backendService.getData(`${CONFIG.proxyApiUrlBase}/securityid/${this.issuerID}/${selectedSecurityID}`)
   // this.backendService.getData(`${CONFIG.proxyApiUrlBase}`)
      .subscribe(response => {
        console.log(response);
        // this.proxydata = response['myCustomer'];
        this.proxydata1 = response['myCustomer1'];
        //this.checkedItems = new Array(this.proxydata1.length).fill(false);
        console.log(this.checkedItems)
        console.log(this.proxydata1)
        this.issuerID = this.authService.CustomerID
        this.SecurityID=this.authService.CustomerID
         console.log(this.issuerID)
         console.log(this.SecurityID)
        // console.log(this.proxydata.length);
        // if(this.proxydata.length==0)
        // {
        //   this.loadingData=false;
        // }
        // this.proxydatalength=this.proxydata.length;
        
        // console.log(this.proxydata);
         this.loadingData=true;
      });
    // this.filteredProxyData = this.proxydata1;
    // console.log(this.filteredProxyData)
    // if (selectedSecurityID === '') {
    //   this.filteredProxyData = this.proxydata1;
    //   console.log(this.proxydata1)
    // } else {
      
    //   this.proxydata1 = this.filteredProxyData.filter(item => item.securityID === selectedSecurityID);
      
    //   console.log(this.proxydata1)
    //   console.log(this.filteredProxyData)
      
    // }
  }
  filterSecurityIDName() {
    var selectedSecurityIDName = this.FilterByStatus.controls['securityIDName'].value;
    //const selectedSecurityID1 = this.FilterByStatus.get('securityID1').value;
    const selectedSecurityID = this.FilterByStatus.get('securityID').value;
    console.log(selectedSecurityID)
    this.backendService.getData(`${CONFIG.proxyApiUrlBase}/securityid/${this.issuerID}/${selectedSecurityID}/${selectedSecurityIDName}`)
   // this.backendService.getData(`${CONFIG.proxyApiUrlBase}`)
      .subscribe(response => {
        console.log(response);
        // this.proxydata = response['myCustomer'];
        this.proxydata1 = response['myCustomer1'];
        //this.checkedItems = new Array(this.proxydata1.length).fill(false);
        console.log(this.checkedItems)
        console.log(this.proxydata1)
        this.issuerID = this.authService.CustomerID
        this.SecurityID=this.authService.CustomerID
         console.log(this.issuerID)
         console.log(this.SecurityID)
        // console.log(this.proxydata.length);
        // if(this.proxydata.length==0)
        // {
        //   this.loadingData=false;
        // }
        // this.proxydatalength=this.proxydata.length;
        
        // console.log(this.proxydata);
         this.loadingData=true;
      });
    // this.filteredProxyData = this.proxydata1;
    // console.log(this.filteredProxyData)
    // if (selectedSecurityID === '') {
    //   this.filteredProxyData = this.proxydata1;
    //   console.log(this.proxydata1)
    // } else {
      
    //   this.proxydata1 = this.filteredProxyData.filter(item => item.securityID === selectedSecurityID);
      
    //   console.log(this.proxydata1)
    //   console.log(this.filteredProxyData)
      
    // }
    // console.log(selectedSecurityIDName)
    // this.filteredProxyData = this.proxydata1;
    // console.log(this.filteredProxyData)
    // if (selectedSecurityIDName === '') {
    //   this.filteredProxyData = this.proxydata1;
    //   console.log(this.proxydata1)
    // } else {
      
    //   this.proxydata1 = this.filteredProxyData.filter(item => item.shortname === selectedSecurityIDName);
      
    //   console.log(this.proxydata1)
    //   console.log(this.filteredProxyData)
      
    // }
  }
  filterSecurityIDAcc(){
    const selectedSecurityID = this.FilterByStatus.get('securityIDacc').value;
    console.log(selectedSecurityID)
    let meetinddatesec=this._datepipe.transform(this.meetingsecdate, "MM-dd-yyyy");
    console.log(meetinddatesec)
    let startdate = this._datepipe.transform(this.onlymeetingdate, "MM-dd-yyyy");
    console.log(startdate)
    let recorddate2=this._datepipe.transform(this.recorddateonlysec, "MM-dd-yyyy");
    console.log(recorddate2)
    this.proxydata=null;
    this.backendService.getData(`${CONFIG.proxyApiUrlBase}/${this.issuerID}/${recorddate2}/${meetinddatesec}/${this.proxydataa2}/${selectedSecurityID}`)
     // this.backendService.getData(`${CONFIG.proxyApiUrlBase}`)
        .subscribe(response => {
          console.log(response);
          this.proxydata = response['myCustomer'];
          console.log(this.proxydata)
          //this.proxydataa = response['myshares'];
          this.proxydataa=response['formattedNumber']
          //this.proxydataa1=response['totalSharevalue'];
          this.proxydataa1=response['formattedNumber1'];
          this.proxydataa2=response['securityId1'];
          this.meetingtime=response['meetingdatetime'];

          this.accountiddrop=response['accountiddrop'];
          console.log(this.accountiddrop)

          this.onlymeetingdate=response['meetingdate']
          const date = new Date(this.onlymeetingdate);
          const formattedDate = date.toLocaleDateString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric'
          });
          console.log(formattedDate)
          //this.testdate=response[formattedDate];
          //console.log(this.testdate)
          this.Location=response['location'];
          this.recorddate=response['recorddate'];
          console.log(this.recorddate)

          console.log(this.meetingtime)
          this.recorddateolny=response['recorddateonly'];
          console.log(this.recorddateolny)
          this.formatdate1=response['dateOnly']
          console.log(this.formatdate1)
          this.clientname=response['client']
          console.log(this.onlymeetingdate)
          console.log(this.proxydataa2)
          this.issuerID = this.authService.CustomerID
          console.log(this.issuerID)
          console.log(this.proxydata.length);
          console.log(this.proxydata);
          if(this.proxydata.length==0)
          {
            this.loadingData=true;
          }
          this.proxydatalength=this.proxydata.length;
          
          console.log(this.proxydata);
          this.loadingData=true;
          this.showDetails = true;
          this.showProxy = false;
        });
      }
      clear(){
        console.log("@@@@@@@@@@@@@@@@@@")
        this.clearthecontrolls()
        let meetinddatesec=this._datepipe.transform(this.meetingsecdate, "MM-dd-yyyy");
    console.log(meetinddatesec)
    let recorddate2=this._datepipe.transform(this.recorddateonlysec, "MM-dd-yyyy");
    console.log(recorddate2)
       this.getDetails(meetinddatesec,this.proxydataa2,recorddate2);
        
      }
      clearthecontrolls(){
        this.FilterByStatus.controls['securityIDacc'].setValue('');
        //this.FilterByStatus.controls['securityType'].setValue('');
      }
}










  

 
 
 
 






