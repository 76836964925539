import CONFIG from '../../config';
import { Injectable } from '@angular/core';
//import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';

import { LoadingService } from '../loading-spinner/loading.service';
import { catchError } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccountService } from './account.service';

@Injectable()
export class AuthService {


    currentSAccount;
    currentSettlementAccountSubscription:Subscription;

    public Filename:string = localStorage.getItem('Filename') || sessionStorage.getItem('Filename');
    public backendresponse:string=localStorage.getItem('backendresponse') || sessionStorage.getItem('backendresponse');

    public loggedIn: boolean;
    public CustomerID: string = localStorage.getItem('CustomerID') || sessionStorage.getItem('CustomerID');
    public WebUserID: string = localStorage.getItem('WebUserID') || sessionStorage.getItem('WebUserID');
    public token: string = localStorage.getItem('token') || sessionStorage.getItem('token');
    public LoginType:string = localStorage.getItem('LoginType') || sessionStorage.getItem('LoginType');
    public CustomerName:string = localStorage.getItem('CustomerName') || sessionStorage.getItem('CustomerName');
    public CustomerType:string = localStorage.getItem('CustomerType') || sessionStorage.getItem('CustomerType');
    public UserID:string = localStorage.getItem('UserID') || sessionStorage.getItem('UserID');
    public singleIssuerID:string = localStorage.getItem('singleIssuerID') || sessionStorage.getItem('singleIssuerID');
    public IssuerCount:string = localStorage.getItem('IssuerCount') || sessionStorage.getItem('IssuerCount');
    
    public btnRefresh:string = localStorage.getItem('btnRefresh') || sessionStorage.getItem('btnRefresh');

    //public Proxypostedto:string=localStorage.getItem('Proxypostedto') || sessionStorage.getItem('Proxypostedto');
    constructor(
        private _http: HttpClient,
        private _router: Router,
        private _loadingService: LoadingService,
        private accountService:AccountService
    ) 
    {
        
        this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
       //s console.log(this.currentSettlementAccountSubscription);
        this.currentSAccount = this.accountService.currentSettlementAccount;
       //s console.log(this.currentSAccount);
    }

    currentSettlementAccountChange(value) {
        this.currentSAccount = value;
        
      }
      login(loginData): Observable<any> {
        console.log( )
     this._loadingService.show();
         const body = JSON.stringify(loginData);
 
         let headers = new HttpHeaders({
             'Content-Type': 'application/json',
             'Accept': 'application/json'
          });
          let options = {
             headers: headers
          }
 
         // const headers = new Headers({
         //     'Content-Type': 'application/json',
         //     'Accept': 'application/json'
         // });
         // const options = new RequestOptions({ headers: headers });
 
         console.log(CONFIG.loginApiUrlBase, body, options)    
         return this._http.post(CONFIG.loginApiUrlBase, body, options)
         .pipe(
             catchError(this.handleError)
         );
             // .map(response => {
             //     return response;
             // })
             // .catch(this.handleError)
             // .finally(() => {
             //     this._loadingService.hide();
             // });
     }
 
  

 
    logout() {
        this._loadingService.show();
        this.loggedIn = false;  
      //s  console.log('Entered into logged out method.'); 
        this.accountService.AClicked("");
        this.accountService.setSAccount("");
      //ss  console.log( this.currentSAccount);
        this._http.get(CONFIG.loginApiUrlBase)
            .subscribe(response => {
                this._loadingService.hide();

                //if the user had the 'remember me' flag enabled, session info stored in localStorage
                if (localStorage.getItem('token') !== null) {
                    localStorage.removeItem('Expires/Max-Age');
                    localStorage.removeItem('CustomerID');
                    localStorage.removeItem('token');
                    localStorage.removeItem('CustomerName');
                    localStorage.removeItem('UserID');
                    sessionStorage.removeItem('singleIssuerID');
                    sessionStorage.removeItem('IssuerCount');
                   
                }

                //if the user did not have the 'remember me' flag enabled, session info stored in sessionStorage
                if (sessionStorage.getItem('token') !== null) {
                    localStorage.removeItem('CustomerID');
                    sessionStorage.removeItem('token');
                    sessionStorage.removeItem('CustomerName');
                    sessionStorage.removeItem('UserID');
                    sessionStorage.removeItem('singleIssuerID');
                    sessionStorage.removeItem('IssuerCount');
                    
                }

                //remove locally cached identity variables
                this.token = null;
                this.loggedIn = false;
                this.CustomerID = null;

                this._router.navigate(['']);
            },
            error => {
                this.handleError(error);
            },
            () => {
                this._loadingService.hide();
            });
    }

    /*
    Utility error handler function for server errors
    */
    private handleError(error) {
        const errMsg = error.message || 'Server error';
        return Observable.throw(error);
    }
}