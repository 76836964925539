import { Component, Input, OnInit, Output } from '@angular/core';
import CONFIG from '../../../config';
import { AuthService } from '../../shared/auth.service';
import { BackendService } from '../../shared/backend.service';
import { OrderByService } from '../../shared/orderby.service';
import { PaginationService } from '../../shared/pagination.service';
//import * as json2csv from 'json2csv';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { saveAs as importedSaveAs } from 'file-saver';
import { Subscription } from 'rxjs/Subscription';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from 'app/notification/notification.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd,Router } from '@angular/router';
import { CurrencyPipe, Location } from '@angular/common';
import { Utilities } from 'app/shared/utilities.service';
import { SearchService } from 'app/shared/search.service';
import { MultiColumnGridService } from 'app/shared/multi-column-grid.service';
import { DocummentscontainerComponent } from './docummentscontainer/docummentscontainer.component';
import { AccountService } from 'app/shared/account.service';
import { Observable,  interval } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FilterService } from '../../shared/filter.service';
import { subscriptionLogsToBeFn } from 'rxjs/internal/testing/TestScheduler';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  providers: [PaginationService,DatePipe]
})
export class DocumentsComponent implements OnInit {
 dateRequested: string;
  apiUrl: string = '';
  fields: string[] = [];
  fieldNames: string[] = [];
  defaultSortOrder: string = '';
  currentSortOrder: string = '';
  documents: any;
  filteredDocuments: Object[] = [];
  fileslist: Object[] = [];
  totalEntries: number = 0;
  noEntrys: boolean = false;
  entryRange: Array<number>;
  entryRangeSubscription: Subscription;
  currentRoute: any;  
  exportTitle: string = 'Exported-Data';
  active: string = '';
  files: any[];
  count: number = 0;
  title: any;
  fileExt: any;
  DocID: any;
  UserID: any;
  loadingData: boolean = false;
  pagenlenth: boolean = true;
  alignstatus: boolean = false;
  hidegrid: boolean = true;
  reportGenerating: boolean = false;
  cuurentSubDate:Subscription;
  currentSettlementAccountSubscription: Subscription;
  currentSettlementAccount = '';
  currentSAccount;
  maxdocid: any;
  datefilteredaudit: Object[] = [];
  issuerID:any;
  securityIDList: any;
  startdate = new FormControl("");
  enddate = new FormControl();
  datefilteroriginalitems: any;
  exportItems: Object[];
  FilterByStatus: any;
  securityIDList1: any;
  DateFilter:FormGroup;
  dateOnly1: any;
  tempstartdate:any;  
  documentDate: string;
  prevurl:string;
  constructor(
    public _formBuilder: FormBuilder,
    public router: Router,
    public _location: Location,
    public notificationService: NotificationService,
    public authService: AuthService,
    public backendService: BackendService,
    public dialog: MatDialog,
    public searchService: SearchService,
    public orderByService: OrderByService,
    public paginationService: PaginationService,
    public currencyPipe: CurrencyPipe,
    public accountService: AccountService,
    public datePipe: DatePipe,
    public filterService: FilterService,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute

  ) {
     
    // super(_formBuilder,router,_location,notificationService,backendService,authService,dialog,
    //   searchService,orderByService,paginationService,currencyPipe);
    
    //console.log(this.prevurl);
      this.DateFilter = this.formBuilder.group({
        'startdate': [''],
        'enddate': ['']
      });  
     
      //this.startdate = new FormControl(new Date());
    // const source=interval(60000);
    // this.entryRangeSubscription = source.subscribe(val => this.ngOnInit());
    router.events.filter(event => event instanceof NavigationEnd)
    .subscribe(event => { 
      this.currentRoute = event['url'];       
    });
    //this.prevurl=this.router.getCurrentNavigation().previousNavigation.finalUrl.toString();
    const currentNavigation = this.router.getCurrentNavigation();
if (currentNavigation && currentNavigation.previousNavigation) {
  this.prevurl = currentNavigation.previousNavigation.finalUrl.toString();
} else {
  // Handle case where previousNavigation or finalUrl is null/undefined
  this.prevurl = ''; // or some default value or error handling
}
  }
  ngOnChanges(){
    console.log(this.dateRequested)
  }

  ngOnInit() {
   
    //this.cuurentSubDate=this.accountService.currentSelectedDateChange$.subscribe(currentSelectedDate => this.currentdateChange(currentSelectedDate));
    this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
    console.log(this.currentSettlementAccountSubscription);
    this.currentSAccount = this.accountService.currentSettlementAccount;
    this.dateRequested= this.accountService.currentSelectedDate
    console.log(this.currentSAccount);

    // this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.
    // subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
    this.apiUrl = CONFIG.documentsApiUrlBase
    this.fields = ['documentDate', 'documentType', 'fileExt'];
    this.fieldNames = ['Date', 'Documents', 'FileType'];
    this.defaultSortOrder = 'documentDate';

    // if(this.authService.LoginType=="Shareholder")
    // {
    //   this.issuerID=this.authService.CustomerID;
    // }
    // else if(this.authService.IssuerCount=="1" && this.authService.LoginType=="Issuer")
    //   this.issuerID=this.authService.singleIssuerID;
    // else 
    //   this.issuerID=this.currentSAccount;


      if(this.authService.LoginType=="Issuer")
      {
        if(this.authService.IssuerCount=="1")
        {
          this.issuerID=this.authService.singleIssuerID;
        }
        else
        {
          this.issuerID=this.currentSAccount;
        }
      }
      else if(this.authService.LoginType=="ADVISOR")
      {
        this.issuerID=this.authService.CustomerID;
       
      }
      else
      {
        if(this.authService.IssuerCount=="1")
        {
          this.issuerID=this.authService.singleIssuerID;
        }
        else
        {
          this.issuerID=this.currentSAccount;
        }
      }
     


      //this.startdate = new FormControl(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));

      this.route.params.subscribe(params => {
        if(this.prevurl=="/dashboard/proxy"){
        this.documentDate =this.dateRequested;
      }

        this.getDocuments(this.documentDate);
        this.documentDate=null;
      });
    //   this.startdate = new FormControl(new Date());
  //this.getDocuments();
    // //var s1=this.datePipe.transform(this.enddate.value, "MM-dd-yyyy");
     //var s2=this.datePipe.transform(this.startdate.value, "MM-dd-yyyy")
    //this.startDateFilter(s2)
    // this.startDateFilter(s2)
    //console.log(s2)
    //this.startDateFilter(this.startdate,this.enddate)
    
    // this.route.params.subscribe(params => {
    //   if (params['documents']) {
    //     this.documents = params['documents'];
    //     this.filteredDocuments = this.documents;
    //     this.totalEntries = this.filteredDocuments.length;
    //     this.loadingData = true;
    //     this.pagenlenth = false;
    //     if (this.filteredDocuments.length === 0) {
    //       this.noEntrys = true;
    //     }
    //     this.orderBy(this.defaultSortOrder, this.totalEntries);
    //     // this.setGrid(1, this.totalEntries);
    //     this.paginationSubscription();
    //   }
    // });
    // console.log(this.formatDataForSearch);
    // this.serviceInit(this.formatDataForSearch);
    console.log(this.authService.LoginType);
    if (this.authService.LoginType == "Issuer")
      this.alignstatus = true;
    else
      this.alignstatus = true;

      this.FilterByStatus = new FormGroup({
        securityID: new FormControl(''),
        securityIDName:new FormControl('')
      });
     // this.startDateFilter(s2)
  }
  currentSettlementAccountChange(value) {
    this.currentSAccount = value;
    
  }
  currentdateChange(value) {
    this.dateRequested = value;
    
  }

  getDocuments(documentDate: string) {
    console.log(this.issuerID);

    if(documentDate==undefined ||  documentDate== ""){
      documentDate='12-30-1899';
    }
    else{
      documentDate=documentDate
    }
   //var defaultdate = '12-30-1899';
    var Documenttype='ALL';
    //console.log(defaultdate)
    this.backendService.getData(`${this.apiUrl}/${this.issuerID}/${this.authService.LoginType}/${documentDate}/${Documenttype}`)
      .subscribe(response => {
        this.documents = response["documents"];
        this.maxdocid=response["maxdocid"];
        this.securityIDList=response['filetype'];
        this.securityIDList1=response['documenttype'];
        this.dateOnly1=response['dateOnly'];
        console.log(this.dateOnly1)
        console.log(this.documents);
        this.filteredDocuments = this.documents;
        this.totalEntries = this.filteredDocuments.length;
        this.loadingData = true;
        this.pagenlenth = false;
        if (this.filteredDocuments.length === 0) {
          this.noEntrys = true;
        }
        let startdate2=this.dateOnly1
    console.log(startdate2)
    var startdate1 = this.datePipe.transform(startdate2, "MM-dd-yyyy ");
    //this.startDateFilter( startdate1)
    console.log( startdate1)
        this.orderBy(this.defaultSortOrder, this.totalEntries);
        // this.setGrid(1, this.totalEntries);
        this.paginationSubscription();
      },
        error => {
          // this.serverErrorOrTimeout(error);
        });

   // this.alertmessagecheck();
   

  }
  // close(){

  //   this.reportGenerating = false;
  //   this.pagenlenth = false;
  //   console.log(this.pagenlenth,"close")
  //   }
  alertmessagecheck() {
    this.backendService.getData(`${CONFIG.documentsRefreshReportsApiUrlBase}/${this.currentSAccount}/${this.authService.LoginType}`)
      .subscribe(response => {
        console.log(response);
        // if (!response['result']) {
        //   this.pagenlenth = true;
        //   this.reportGenerating = true;
        //   console.log(this.pagenlenth,"return false")
          
        // }
        // else {
        //   this.pagenlenth = false;
        //   this.reportGenerating = false;
        //   console.log(this.pagenlenth,"return true")
        // }
      });
      
  }

  setGrid(start: number, length?: number) {
    let sentLen = length;

    if (length === undefined) {
      sentLen = this.documents.length;
    }
    this.paginationService.setGrid(start, sentLen);
  }
  paginationSubscription() {
    this.entryRangeSubscription = this.paginationService.entryRange
      .subscribe(range => {
        this.entryRange = range;
        console.log(this.entryRange[0])
        // this.filteredDocuments = this.documents;
        this.filteredDocuments = this.documents.slice(this.entryRange[0], this.entryRange[1]);
        this.totalEntries = this.documents.length;
        this.loadingData = true;
        this.pagenlenth = false;
      });
  }
  orderBy(param, length?: number) {
    if (param !== '') {
      this.currentSortOrder = param;
    } else {
      param = this.defaultSortOrder;
    };
    let itemsToOrder = this.documents;
    let sortingByCurrentShares = false;
    const altParam = param === this.defaultSortOrder ? undefined : this.defaultSortOrder;
    this.documents = this.orderByService.orderByParam(param, itemsToOrder, altParam,"","documetnsdesc");
    if (length) {
      var itemsLength: number = length;
      console.log('length')
    } else {
      var itemsLength: number = itemsToOrder.length;
    }

    this.setGrid(1, itemsLength);

  }
  //   const req = new HttpRequest('GET', '/download/file', {
  //   reportProgress: true,
  // });

  // http.request(req).subscribe(event => {
  //   // Via this API, you get access to the raw event stream.
  //   // Look for download progress events.
  //   if (event.type === HttpEventType.DownloadProgress) {
  //     // This is an download progress event. Compute and show the % done:
  //     const percentDone = Math.round(100 * event.loaded / event.total);
  //     console.log(`File is ${percentDone}% downloaded.`);
  //   } else if (event instanceof HttpResponse) {
  //     console.log('File is completely downloaded!');
  //   }
  // });

  exportData(submittedData?: Object[]) {
    //generate csv data- some more complicated components will pass custom data (Users, for one)
    //others using this grid will use originalItems
    const data = submittedData || this.filteredDocuments;
    const csvData = { data };

    //if field to include and custom titles passed, include those
    if (this.fields.length > 0) { csvData['fields'] = this.fields; }
    if (this.fieldNames.length > 0) { csvData['fieldNames'] = this.fieldNames; }

    //add the proper headers

    //  const csv = 'data:text/csv;charset=utf-8,' + json2csv(csvData);

    //create the link to click and automatically click it (must be a better way?)
    const link = document.createElement('a');
    //   link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', `${this.exportTitle}.csv`);
    document.body.appendChild(link); // Link must be in DOM for Firefox
    link.click();
    document.body.removeChild(link);
  }
  exportFile(item) {
    this.loadingData = false;
    this.DocID = item.docID;
    //console.log(this.DocID, item.docRef2, item);
    //this.UserID = this.authService.CustomerID;
    this.backendService.downloadFile(this.DocID, item.docRef2).subscribe(blob => {
      importedSaveAs(blob, item.docRef2 + "." + item.fileExt);
      this.loadingData = true;
    })
  }
  Deletefile(item)
  {this.loadingData = false;
    this.DocID = item.docID;
    console.log(this.DocID, item.docRef2, item);
    //this.UserID = this.authService.CustomerID;
    this.backendService.deleteFile(this.DocID, item.docRef2).subscribe(blob => {
     // importedSaveAs(blob, item.docRef2 + "." + item.fileExt);
      this.loadingData = true;
      if (this.currentRoute == "/dashboard/document") {
        this.router.navigate(['/dashboard/document/documents']);
      }
      else {
        this.router.navigate(['/dashboard/document']);
      }



  })
}

  //makes table rows expandable in mobile screensize
  makeActive(item) {
    this.active = this.active === item ? '' : item;
  }
  // startDateFilter() {
  //   console.log("started date filter");
  //   this.dateFilter(this.enddate.value, this.startdate.value);
//}
startDateFilter(sdate) {
  //console.log(this.term.value);
  if (sdate == undefined) {
      sdate = this.startdate.value;
  }
  console.log(sdate)
 // console.log(edate, sdate);
  var startdate = this.datePipe.transform(sdate, "MM-dd-yyyy");
  this.tempstartdate=startdate;
  console.log(this.tempstartdate)
  //var startdate = this.datePipe.transform(sdate, "MM/dd/yyyy");
  //var enddate = this.datePipe.transform(edate, "MM/dd/yyyy");

  //this.backendService.getData(`${this.apiUrl}/${this.issuerID}/${this.authService.LoginType}/${startdate}`)
  var defaultdate = startdate;
    var Documenttype=this.FilterByStatus.controls['securityIDName'].value;
    console.log(defaultdate)

    console.log(Documenttype)
     Documenttype='ALL';
    this.backendService.getData(`${this.apiUrl}/${this.issuerID}/${this.authService.LoginType}/${defaultdate}/${Documenttype}`)
  .subscribe(response => {
    this.documents = response["documents"];
    this.maxdocid=response["maxdocid"];
    this.securityIDList=response['filetype']
    console.log(this.documents);
    this.filteredDocuments = this.documents;
    //this.totalEntries = this.filteredDocuments.length;
    this.loadingData = true;
    this.pagenlenth = false;
    if (this.filteredDocuments.length === 0) {
      this.noEntrys = true;
    }
    this.orderBy(this.defaultSortOrder, this.totalEntries);
    // this.setGrid(1, this.totalEntries);
    this.paginationSubscription();
  },
    error => {
      // this.serverErrorOrTimeout(error);
    });
  
  
}
filterSecurityID() {
  //let startdate = this.datePipe.transform(this.startDateFilter.value.startdate, "MM-dd-yyyy");
  let startdate = this.datePipe.transform(this.startdate.value, "MM-dd-yyyy");
  //let startdate = this.datePipe.transform(this.startdate.value, "MM-dd-yyyy");
  // let sdate=this.DateFilter.value.startdate
  // let startdate = this.datePipe.transform(sdate, "MM-dd-yyyy");
  console.log(startdate)
  const selectedSecurityID = this.FilterByStatus.get('securityID').value;
  console.log(selectedSecurityID)
  this.backendService.getData(`${this.apiUrl}/${this.issuerID}/${this.authService.LoginType}/filetype/${selectedSecurityID}`)
  .subscribe(response => {
    this.documents = response["documents"];
    this.maxdocid=response["maxdocid"];
    this.securityIDList=response['filetype']
    console.log(this.documents);
    this.filteredDocuments = this.documents;
    this.totalEntries = this.filteredDocuments.length;
    this.loadingData = true;
    this.pagenlenth = false;
    if (this.filteredDocuments.length === 0) {
      this.noEntrys = true;
    }
    this.orderBy(this.defaultSortOrder, this.totalEntries);
    // this.setGrid(1, this.totalEntries);
    this.paginationSubscription();
  },
    error => {
      // this.serverErrorOrTimeout(error);
    });
  }
  filterSecurityIDName(){
    console.log(this.tempstartdate);
     if (this.tempstartdate == undefined) 
      {
         var startdate = '12-30-1899' 
       } 
     else{
        startdate = this.tempstartdate;
       console.log(startdate)
    }

    //var startdate = this.FilterByStatus.controls['startdate'].value;
    var selectedSecurityIDName = this.FilterByStatus.controls['securityIDName'].value;
    var defaultdate=startdate;
    var Documenttype=selectedSecurityIDName
    // this.backendService.getData(`${this.apiUrl}/${this.issuerID}/${this.authService.LoginType}/documenttype/${selectedSecurityIDName}/${startdate}`)
    this.backendService.getData(`${this.apiUrl}/${this.issuerID}/${this.authService.LoginType}/${defaultdate}/${Documenttype}`)
    .subscribe(response => {
    this.documents = response["documents"];
    this.maxdocid=response["maxdocid"];
    this.securityIDList=response['filetype']
    console.log(this.documents);
    this.filteredDocuments = this.documents;
    this.totalEntries = this.filteredDocuments.length;
    this.loadingData = true;
    this.pagenlenth = false;
    if (this.filteredDocuments.length === 0) {
      this.noEntrys = true;
    }
    this.orderBy(this.defaultSortOrder, this.totalEntries);
    // this.setGrid(1, this.totalEntries);
    this.paginationSubscription();
  },
    error => {
      // this.serverErrorOrTimeout(error);
    });

  }
}
