import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

//components
import { NotificationComponent } from '../notification/notification.component';

//services
import { AuthService } from '../shared/auth.service';
import { BackendService } from '../shared/backend.service';
import { NotificationService } from '../notification/notification.service';
import { ResetFormService } from './reset-form.service';
import CONFIG from 'config';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    //providers: [ResetFormService]
})

export class LoginComponent implements OnInit {
    loginUser: FormGroup;
    submitted: Boolean = false;
   // pageload: Boolean = true;
    intendedRoute: string;
    image: SafeUrl | null = null;
    browserName:boolean=false;

    constructor(
        public _authService: AuthService,
        public _backendService: BackendService,
        public _router: Router,
        public _formBuilder: FormBuilder,
        public _notificationService: NotificationService,
        public _resetFormService: ResetFormService,
        private sanitizer: DomSanitizer
    ) {
        this.setForm();
    }

    ngOnInit() {
        //this.pageload = true;
    //sconsole.log(this.getBrowserName());

      if(this.getBrowserName()=="ie"){

        this.browserName=true;
        }

      else{
        this.browserName=false;
       }

    }


    public getBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase()
        switch (true) {
          case agent.indexOf('ie') > -1:
            return 'ie';
          case agent.indexOf('opr') > -1 && !!(<any>window).opr:
            return 'opera';
          case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
            return 'chrome';
          case agent.indexOf('trident') > -1:
            return 'ie';
          case agent.indexOf('firefox') > -1:
            return 'firefox';
          case agent.indexOf('safari') > -1:
            return 'safari';
          default:
            return 'other';
        }
    }


    getLogo() {
        const mediaType = 'application/image';
        this._backendService.download(this._authService.CustomerID).subscribe(value => {
            const blob = new Blob([value], { type: mediaType });
            const unsafeImg = URL.createObjectURL(blob);
            this.image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
        }, error1 => {
            console.log("download error");
        });
    }

    login(user) {
console.log(user);
        //allow us to check if user has submitted form- for validation display
        this.submitted = true;

        this._authService.login(user)
            .subscribe(response => {
                console.log('login response:', response);
                if (response['result']) {
                    this._notificationService.showNotification({ type: 'success', message: response['msg'] });
                    this._authService.loggedIn = true;

                    sessionStorage.setItem('CustomerID', response['customerID']);
                    this._authService.CustomerID = sessionStorage.getItem('CustomerID');
                    localStorage.setItem('token', response['access_token']);
                    sessionStorage.setItem('token', response['access_token']);
                    this._authService.token = sessionStorage.getItem('token');
                    sessionStorage.setItem('LoginType', response['logintype']);
                    this._authService.LoginType = sessionStorage.getItem('LoginType');

                    sessionStorage.setItem('CustomerType', response['customerType']);
                    this._authService.CustomerType = sessionStorage.getItem('CustomerType');

                    sessionStorage.setItem('CustomerName', response['customerName']);
                    this._authService.CustomerName = sessionStorage.getItem('CustomerName');

                    sessionStorage.setItem('UserID', response['userID']);
                    this._authService.UserID = sessionStorage.getItem('UserID');

                    sessionStorage.setItem('WebUserID', response['webuserID']);
                    this._authService.WebUserID = sessionStorage.getItem('WebUserID');
console.log(this._authService.WebUserID)
                    this.submitted = false;
                   
                    if (response['requireChange'] == "True") {
                        this._notificationService.showNotification({ type: 'warning', message: 'Please reset your password' });
                       // this._router.navigate(['/set-security-questions']);
                       this._router.navigate(['/dashboard/profile']);
                    }
                    else {
                        console.log("test else");
                        this._notificationService.showNotification({ type: 'success', message: response['msg'] });
                        this._router.navigate(['/dashboard']);
                    }
                } else {
                    this.loginUser.controls['CustomerID'].setValue('');
                    this.loginUser.controls['WebUserID'].setValue('');
                    this.loginUser.controls['Password'].setValue('');
                    this._notificationService.showNotification({ type: 'error', message: response['msg'] });
                   // this.pageload = true;
                }
            },
                //server error
                (error) => {
                    this._notificationService.showNotification({ type: 'error', message: 'Server Error' });
                   // this.pageload = true;
                });

    }
    ValidateCode(user) {
       //s console.log("testtt");
        this._backendService.postData(user, `${CONFIG.tempcodeApiUrlBase}`)
            .subscribe(response => {
                if (response['result']) {
                    this._notificationService.showNotification({ type: 'success', message: response['msg'] });
                    this._authService.loggedIn = true;
                    this.submitted = false;
                   // this.pageload = false;
                   //s console.log(response['requireChange']);
                    if (response['requireChange'] == "True") {
                        this._notificationService.showNotification({ type: 'warning', message: 'Please reset your password' });
                        this._router.navigate(['/set-security-questions']);
                    }
                    else {
                        //console.log("test else");
                        this._notificationService.showNotification({ type: 'success', message: response['msg'] });
                        this._router.navigate(['/dashboard']);
                    }

                    // this._router.navigate(['/dashboard']);
                }
                else {
                    this._notificationService.showNotification({ type: 'error', message: response['msg'] });
                   // this.pageload = true;
                }
            })
    }
    forgot() {
        console.log('error forgot');
    }

    activateSubRoute(rt) {
      //s  console.log(rt);
        this._router.navigate(['/dashboard'], { preserveQueryParams: true });
    }

    setForm() {       
        this.loginUser = this._formBuilder.group({
            'CustomerID': ['', Validators.required],
            'Password': ['', Validators.required],
            //'LoginType': ['', Validators.required]
            //'TempCode': ['', Validators.required]
        });
    }
}
